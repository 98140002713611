import React, { useCallback, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { API } from '../../common/api-end-point';
import { appName } from '../../common/constant';
import { ApiService } from '../../services/ApiService';
import debounceEffect from '../../utils/debounce';
import HeroImageVinyl from '../svg/HeroImageVinyl';
import YellowText from './BlueText';

const Container = styled.div`
    height: 100vh;
    display: flex;
    position: relative;
    // overflow: hidden;
    margin-bottom: 4em;
    width: 100%;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;
const HeroImageVinylContainer = styled.div`
    position: absolute;
    width: 40%;
    height: 100%;
    top: 18%;
    right: 2%;
    z-index: -1;
`;
const HeroSectionBottom = styled.div`
    position: absolute;
    bottom: 5%;
    left: -2%;
    height: 1rem;
    width: 105%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    transform: rotate(-2deg);
`;
const Left = styled.div`
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
const TextContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    // gap: 20px;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
const HeroText = styled.h1`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 3em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @media screen and (max-width: 768px) {
        font-size: 1.5em;
        text-align: center;
    }
`;
const Desc = styled.h4`
    width: 80%;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 1em;
    @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
    }
`;
const SearchContainer = styled.div`
    width: 60%;
    height: 3rem;
    padding: 5px;
    margin-top: 20px;
    background: #ffffff;
    box-shadow: -3px 5px 6px #6dbcc7;
    border-radius: 10px;
    border: 1px solid #0994e542;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
const SearchInput = styled.input`
    border: none;
    outline: none;
    background: none;
    font-size: 16px;
    width: 65%;
    margin-left: -15px;
`;
const SearchButton = styled.button`
    padding: 10px 16px;
    font-size: 0.9em;
    border: none;
    background: #1e3783;
    color: white;
    border-radius: 10px;
    cursor: pointer;
`;
const Right = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
`;
const HeroImageContainer = styled.div`
    width: 80%;
    height: 80%;
`;
const HeroImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const HeroSection = () => {
    const [q, setQ] = useState('');
    const navigate = useNavigate();

    const searchHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        setQ(event.target.value);
    };

    const searchNavigate = (): void => {
        navigate(`/search?q=${q}`, { preventScrollReset: true });
    };

    return (
        <Container>
            <HeroSectionBottom />
            {/* <HeroImageVinylContainer>
                <HeroImageVinyl />
            </HeroImageVinylContainer> */}
            <Left>
                <TextContainer>
                    <HeroText>
                        Get Ahead in Your Career with{' '}
                        <YellowText>{appName}</YellowText>
                    </HeroText>
                    <Desc>
                        The best way to get your foot in the door of the IT
                        industry is to get certified.
                        <br />
                        Our study guides will help you pass your exam
                        certification and get your dream job.
                    </Desc>
                    <SearchContainer>
                        <BsSearch />
                        <SearchInput onChange={searchHandler} value={q} />
                        <SearchButton onClick={searchNavigate}>
                            SEARCH
                        </SearchButton>
                    </SearchContainer>
                </TextContainer>
            </Left>
            <Right>
                <HeroImageContainer>
                    <HeroImage src="/img/hero.png" />
                </HeroImageContainer>
            </Right>
        </Container>
    );
};

export default HeroSection;
