import { IExam } from '../../interface/interface';
import actions from './action';

const { ADD_SUCCESS, GET_SUCCESS, BEGIN, ERR, GET_SUCCESS_BY_ID } = actions;

export interface State {
    total: number;
    loading: boolean;
    exam: IExam[];
    payload: IExam;
}

const initialState: State = {
    total: 0,
    loading: false,
    exam: [],
    payload: {} as IExam,
};

const examReducer = (state = initialState, action: any) => {
    const { type, data, err } = action;
    switch (type) {
        case BEGIN:
            return {
                ...state,
                loading: true,
            };
        case ADD_SUCCESS:
            return {
                ...state,
                exam: [...state.exam, { ...data }],
                loading: false,
            };
        case GET_SUCCESS:
            return {
                ...state,
                exam: data,
                loading: false,
            };
        case ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        case GET_SUCCESS_BY_ID:
            return {
                ...state,
                payload: data,
                loading: false,
            };
        default:
            return state;
    }
};

export default examReducer;
