import { vendorId } from './constant';

export const API = {
    certificate: {
        get: `/api/certificate/vendor/`,
    },
    vendor: {
        get: `/api/vendor/get-all`,
    },
    exam: {
        get: `/api/exam/certificate/`,
        getOne: `/api/exam/`,
    },
    auth: {
        login: '/api/auth',
        register: '/api/register',
    },
    stripe: {
        intent: '/api/payment/intent/',
        checkout: '/api/payment/checkout',
        transaction: '/api/payment/me/',
    },
    footer: {
        get: `/api/footer/658941aad5f65d001c91083a`,
    },
    search: {
        get: '/api/search',
    },
};
