import React from 'react';
import { styled } from 'styled-components';
import { LeftLogProps } from '../types/types';
import { Link } from 'react-router-dom';
import { appName } from '../common/constant';

const Left = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const PersonContainer = styled.div`
    // position: absolute;
    top: 10%;
    right: -10%;
    width: 45%;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const Person = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const CartContainer = styled.div`
    width: 80%;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const Cart = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const TextContainer = styled.div`
    // position: absolute;
    // top: 20%;
    // left: 25%;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // padding: 10px;
`;
const Header = styled.h1`
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 3rem;
`;
const Name = styled.h2`
    font-family: 'Poppins';
    font-weight: 500;
    width: 100%;
    font-size: 2rem;
`;
const Account = styled.h3`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 1em;
    margin-top: 30px;
    flex: 2;
    gap: 10px;
`;
const LinkWrapper = styled.a`
    color: #6dbcc7;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 1em;
    cursor: pointer;
`;
const LeftLog = ({ signedup }: LeftLogProps) => {
    return (
        <Left>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '70%',
                }}
            >
                <div>
                    <TextContainer>
                        <Header>{signedup ? 'Sing In' : 'Sign Up'} to</Header>
                        <Name>{appName}</Name>
                        <Account>
                            {signedup
                                ? "If you don't have an account"
                                : 'If you already have an account'}
                            <br /> You can {signedup ? 'Sign Up' : 'Login'}{' '}
                            <Link to={signedup ? '/register' : '/login'}>
                                <LinkWrapper>here!</LinkWrapper>
                            </Link>
                        </Account>
                    </TextContainer>
                    <CartContainer>
                        <Cart src="./img/cart.png" />
                    </CartContainer>
                </div>
                <PersonContainer>
                    <Person src="./img/person.png" />
                </PersonContainer>
            </div>
        </Left>
    );
};

export default LeftLog;
