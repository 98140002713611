import React from 'react';
import { styled } from 'styled-components';
import { TextInputProps } from '../types/types';

const Container = styled.div<{ small: boolean }>`
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: ${({ small }) => (small ? '10px' : '20px')};
`;
const IconContainer = styled.div``;
const Label = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;
const Name = styled.h1`
    color: #868686;
    font-family: 'Montserrat';
    font-size: 0.8rem;
`;
const Input = styled.input`
    height: 3rem;
    width: 100%;
    font-size: 14px;
    padding: 0 20px;
    color: #6dbcc7;
    border-radius: 8px;
    border: 1px solid #efede9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    outline: none;
`;

const TextInput = ({
    label,
    icon,
    placeholder,
    type,
    small,
    name,
    value,
    onChange,
}: TextInputProps) => {
    return (
        <Container small={small}>
            <Label>
                {icon && <IconContainer>{icon}</IconContainer>}
                <Name>{label}</Name>
            </Label>
            <Input
                placeholder={placeholder}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
            />
        </Container>
    );
};

export default TextInput;
