import React from 'react';
import { getItem } from '../utils/localStorage';
import { localStorageVars } from '../common/constant';
import { Navigate } from 'react-router-dom';

type PrivateRoutesProps = {
    children: React.ReactNode;
};

const PrivateRoutes: React.FC<PrivateRoutesProps> = ({ children }): any => {
    const hasToken = !!getItem(localStorageVars.token);
    return hasToken ? children : <Navigate to="/login" replace />;
};

export default PrivateRoutes;
