import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { PageTitleProps } from '../types/types';

const Container = styled.div`
    height: 9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    position: relative;
    background-color: #d9d9d9;
    z-index: -2;
`;
const WeirdShape = styled.div`
    border-radius: 0% 100% 27% 100% / 0% 100% 39% 100%;
    height: 8em;
    width: 8em;
    background-color: #d5d000;
    position: absolute;
    left: -7%;
`;
const Circle = styled.div`
    border-radius: 50%;
    height: 8em;
    width: 8em;
    background: linear-gradient(0deg, #1e3783, rgb(255 248 248) 100%);
    position: absolute;
    left: 51%;
    z-index: -1;
`;
const Title = styled.h1`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 2em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
const Path = styled.p`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.9em;
    display: flex;
    gap: 0.5em;
`;
const PathBlue = styled.p`
    color: black;
`;

const PageTitle = ({ title }: PageTitleProps) => {
    return (
        <Container>
            {/* <WeirdShape></WeirdShape> */}
            {/* <Circle></Circle> */}
            <Title>{title}</Title>
            <Path>
                <Link to="/">Home</Link> //{' '}
                <PathBlue> {title.toLowerCase()}</PathBlue>
            </Path>
        </Container>
    );
};

export default PageTitle;
