import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import ListMenu from '../components/ListMenu';
import PageTitle from '../components/PageTitle';
import { getAllVendors } from '../redux/vendor/actionCreator';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    grid-row-gap: 3rem;
    padding: 5rem;
    background: #f4f4f4;
`;

const Vendor = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onNavigate = (slug: string, id: string, name: string) =>
        navigate(`/vendor/${slug}`, {
            state: { name, slug, id },
        });
    const { isLoading, vendor } = useSelector((_state: any) => {
        return {
            isLoading: _state?.vendor?.loading,
            vendor: _state?.vendor?.vendors,
        };
    });
    useEffect(() => {
        dispatch(getAllVendors() as any);
    }, []);
    return (
        <div>
            <PageTitle title={'Vendor'} />
            <Container>
                {isLoading ? (
                    <h1>loading...</h1>
                ) : (
                    Array.isArray(vendor) &&
                    [...vendor].map((item: any, i: number) => {
                        return (
                            <ListMenu
                                id={item._id}
                                key={i}
                                name={item.name}
                                slug={item.slug}
                                primary={i % 2 === 0}
                                onNavigate={onNavigate}
                            />
                        );
                    })
                )}
            </Container>
        </div>
    );
};

export default Vendor;
