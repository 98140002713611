import React from 'react';
import PageTitle from '../components/PageTitle';
import { styled } from 'styled-components';
import Info from '../components/Info';
import { FaBuilding, FaGlobe } from 'react-icons/fa';
import { FiMail, FiSend } from 'react-icons/fi';
import { MdWatchLater } from 'react-icons/md';
import Divider from '../components/Divider';
import { domain, supportEmail } from '../common/constant';

const Container = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
    background: #f4f4f4;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ContainerWrapper = styled.div`
    display: flex;
    align-items: start;
    flex-direction: row;
    // background: #f4f4f4;
    // background: red;
    // width: 100%;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;
const Left = styled.div`
    // flex: 2;
`;
const Form = styled.form`
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // gap: 30px;
`;
const Title = styled.h1`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 2em;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 1.5em;
    }
`;
const Desc = styled.p`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1em;
    text-align: center;
    @media screen and (max-width: 768px) {
    }
`;
const Input = styled.input`
    width: 100%;
    padding: 15px;
    outline: none;
    background: none;
    border: 1px solid #000000;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 1rem;
`;
const Textarea = styled.textarea`
    width: 100%;
    padding: 15px;
    outline: none;
    background: none;
    border: 1px solid #000000;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;
const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 40%;
    padding: 15px 10px;
    color: white;
    border: none;
    background: #358186;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    transition: 0.1s ease-out;
    cursor: pointer;
    &:hover {
        background: #285d61;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
const Right = styled.div`
    padding: 1rem;
`;
const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: white;
    padding: 1rem;
    box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: 1px solid rgba(224, 224, 224, 0.652);
`;

const FormWrapper = styled.div`
    padding: 1rem;
    width: 100%;
`;

const infos = [
    {
        color: '#358186',
        name: 'Office Address',
        desc: '4211, 2367, USA',
        icon: <FaBuilding size={24} color={'#358186'} />,
    },
    {
        color: '#6DBCC7',
        name: 'Website',
        desc: domain,
        icon: <FaGlobe size={24} color={'#358186'} />,
    },
    {
        color: '#6C9A31',
        name: 'Email Us',
        desc: supportEmail,
        icon: <FiMail size={24} color={'#6C9A31'} />,
    },
    {
        color: 'black',
        name: 'Contact Us',
        desc: 'Live Chat 24/7',
        icon: <MdWatchLater size={24} color={'black'} />,
    },
];
const Contact = () => {
    return (
        <>
            <PageTitle title={'Contact'} />
            <Container>
                <ContainerWrapper>
                    <Left>
                        <Form>
                            <Title>Get In Touch</Title>
                            <Desc>
                                Do you want to know more? We'd love to hear from
                                you!
                            </Desc>
                            <FormWrapper>
                                <Input placeholder="Your Name" type="name" />
                                <Input placeholder="Email" type="email" />
                                <Input placeholder="Subject" type="text" />
                                <Textarea placeholder="Subject" rows={15} />
                                <Button>
                                    <FiSend size={20} />
                                    Send Message
                                </Button>
                            </FormWrapper>
                        </Form>
                    </Left>
                    <Right>
                        <InfoContainer>
                            {infos.map((item, i) => {
                                return (
                                    <Info
                                        key={i}
                                        color={item.color}
                                        name={item.name}
                                        desc={item.desc}
                                        icon={item.icon}
                                    />
                                );
                            })}
                        </InfoContainer>
                    </Right>
                </ContainerWrapper>
            </Container>
        </>
    );
};

export default Contact;
