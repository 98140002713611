import React from 'react';
import { styled } from 'styled-components';
import TopSection from '../components/courses/TopSection';
import ResultSection from '../components/courses/ResultSection';
import CommentSection from '../components/courses/CommentSection';
import DescriptionSection from '../components/courses/DescriptionSection';

const Container = styled.div`
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Courses = () => {
    return (
        <>
            <Container>
                <TopSection />
                <ResultSection />
                <DescriptionSection />
                <CommentSection />
            </Container>
        </>
    );
};

export default Courses;
