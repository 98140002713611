import React from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from '../common/api-end-point';
import { localStorageVars } from '../common/constant';
import { ApiService } from '../services/ApiService';

const Payment = () => {
    const navigate = useNavigate();

    function getQueryParams(url: string): Record<string, string> {
        const urlParams = new URLSearchParams(url.split('?')[1]);
        const queryParams: Record<string, string> = {};

        // Convert iterator to an array
        const entries = Array.from(urlParams.entries());

        for (const [key, value] of entries) {
            queryParams[key] = value;
        }

        return queryParams;
    }

    const checkoutHandler = async (): Promise<void> => {
        await ApiService.post(
            API.stripe.checkout,
            {
                cartItems: JSON.parse(
                    localStorage.getItem(localStorageVars.cartItems)!,
                ),
            },
            {
                headers: {},
                signal: 15000,
                useAuthorization: true,
            },
        );
        localStorage.removeItem(localStorageVars.cartItems);
        navigate('/', { replace: true });
    };

    React.useEffect(() => {
        const queryParams = getQueryParams(window.location.search);
        if (!queryParams.PayerID && !queryParams.paymentId) return;
        checkoutHandler();
    }, []);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh',
                    backgroundColor: '#f8f8f8',
                }}
            >
                <div
                    style={{
                        textAlign: 'center',
                        backgroundColor: '#fff',
                        padding: '20px',
                        borderRadius: '8px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <h1 style={{ color: '#4caf50' }}>Payment Successful</h1>
                    <p>Thank you for your purchase!</p>
                    <p>Your payment has been successfully processed.</p>

                    <button
                        style={{
                            backgroundColor: '#4caf50',
                            color: '#fff',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            marginTop: '20px',
                            transition: 'background-color 0.3s ease',
                        }}
                        onClick={() => navigate('/', { replace: true })}
                    >
                        Continue Shopping
                    </button>
                </div>
            </div>
        </>
    );
};

export default Payment;
