import React from 'react';
import { styled } from 'styled-components';
import { BsStarFill } from 'react-icons/bs';

const Container = styled.div`
    display: flex;
    gap: 5px;
`;
const Stars = () => {
    return (
        <Container>
            <BsStarFill color="orange" />
            <BsStarFill color="orange" />
            <BsStarFill color="orange" />
            <BsStarFill color="orange" />
            <BsStarFill color="orange" />
        </Container>
    );
};

export default Stars;
