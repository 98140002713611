import actions from './action';
import { ApiService } from '../../services/ApiService';
import { API } from '../../common/api-end-point';

const { begin, addSuccess, getSuccess, error } = actions;

export const getAllVendors = () => {
    return async (dispatch: any) => {
        dispatch(begin());

        try {
            const response = await ApiService.get(API.vendor.get, {
                signal: 15000,
                useAuthorization: false,
                headers: {},
            });
            const { data } = response;
            dispatch(getSuccess(data.data));
        } catch (err) {
            console.error(err);
            // dispatch(error(err?.message));
        }
    };
};
