import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { ApiService } from '../services/ApiService';
import { API } from '../common/api-end-point';
import { appName, color, domain, supportEmail } from '../common/constant';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #d9d9d9;
    height: auto;
    position: relative;
`;
const Shape = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    float: right;
    width: 80%;
    height: 100%;
    clip-path: polygon(26% 0, 100% 0, 100% 100%, 33% 100%, 43% 25%);
    background: #d5d000;
`;
const TextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: start;
    margin: 2rem;

    @media (max-width: 435px) {
        flex-direction: column;
        width: 100%;
    }
`;
const Left = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 435px) {
        width: 100%;
        padding: 0 1rem;
    }
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 2em;
    color: black;
    gap: 5px;
`;
const Content = styled.p`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 1em;
`;
const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media (max-width: 435px) {
        width: 100%;
        padding: 0 1rem;
    }
`;
const Heading = styled.h1`
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 2em;
    font-weight: bold;
    color: ${color};
    text-align: center;
`;
const Exam = styled.a`
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 1em;
    color: ${color};
    text-align: center;
    width: max-content;
    cursor: pointer;
`;
const AwazExam = styled.div`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1em;
    color: white;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 10px;
    grid-column-gap: 30px;
`;
const Copyright = styled.div`
    padding: 1rem 0;
    width: 80%;
    border-top: 1px solid black;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1em;
    @media (max-width: 435px) {
        text-align: center;
    }
`;
const Footer = () => {
    const [exams, setExams] = useState([]);

    useEffect(() => {
        async function getFooter() {
            try {
                const response = await ApiService.get(API.footer.get, {
                    signal: 15000,
                    useAuthorization: false,
                    headers: {},
                });
                const { data } = response;
                setExams(data.data);
            } catch (err) {
                console.error(err);
            }
        }
        getFooter();
    }, []);

    return (
        <Container>
            <TextContainer>
                <Left>
                    <Header>{appName}</Header>
                    <Content>
                        We are providing comprehensive coverage, we are
                        providing our services from last 8 years. More than
                        80000 satisfied customers. You don't need to be worry
                        about credibility of our services. Kindly feel free to
                        contact us for any query or complaint that will help you
                        to take up the certification problem.
                        <br />
                        {supportEmail}
                    </Content>
                </Left>
                <Right>
                    <Heading>{appName}</Heading>
                    <AwazExam>
                        {exams.map((item: any) => {
                            return <Exam> {item?.sku}</Exam>;
                        })}
                    </AwazExam>
                </Right>
            </TextContainer>
            <Copyright>
                © Copyright {new Date().getFullYear()} {domain} All Rights
                Reserved.
            </Copyright>
        </Container>
    );
};

export default Footer;
