import { styled } from 'styled-components';
import HeroSection from '../components/home/HeroSection';
import ChooseSection from '../components/home/ChooseSection';
import AssuranceSection from '../components/home/AssuranceSection';
import ExploreSection from '../components/home/ExploreSection';
import ReviewSection from '../components/home/ReviewSection';
import { useEffect } from 'react';

const Container = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Home = () => {
    useEffect(() => {
        window.document.title =
            'ITExamsTips - IT Certification with Authentic Best Exam Dumps 2024';
    }, []);
    return (
        <>
            <Container>
                <HeroSection />
                <ChooseSection />
                <AssuranceSection />
                <ExploreSection />
                <ReviewSection />
            </Container>
        </>
    );
};

export default Home;
