import React from 'react';
import { styled } from 'styled-components';
import { CommentTextareaProps } from '../../types/types';

const Input = styled.textarea`
    font-size: 0.8em;
    border: none;
    background: #ffffff;
    border-radius: 50px;
    padding: 2em 1.5em;
    width: 95%;
    resize: none;
`;
const CommentTextarea = ({ placeholder }: CommentTextareaProps) => {
    return <Input placeholder={placeholder} rows={10} />;
};

export default CommentTextarea;
