import React from 'react';
import { styled } from 'styled-components';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';

type Props = {
    children: React.ReactNode;
};

export default function Layout({ children }: Props) {
    return (
        <div
            style={{
                height: '100vh',
            }}
        >
            <Navbar />
            {children}
            <Footer />
        </div>
    );
}
