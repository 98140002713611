import React, { useState, useEffect } from 'react';
import { BiTrash } from 'react-icons/bi';
import styled from 'styled-components';
import { localStorageVars } from '../common/constant';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { ApiService } from '../services/ApiService';

interface Props {
    textAlign: string;
}

const TableHeader = styled.th<Props>`
    padding: 10px;
    border-bottom: 1px solid #ccc;
    text-align: ${(props) => props.textAlign};
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
`;

const TableCell = styled.td<Props>`
    padding: 10px;
    text-align: ${(props) => props.textAlign};
`;

const RemoveButton = styled.button`
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.2s ease-out;
    padding: 0.5em;
    &:hover {
        background-color: #8a251e;
    }
`;

const TotalPriceRow = styled.tr`
    font-weight: bold;
`;

const TotalPriceCell = styled.td`
    padding: 10px;
    text-align: right;
`;

const CheckoutButton = styled.button`
    height: 50px;
    width: 8em;
    background-color: #358186;
    color: white;
    font-size: 16px;
    font-weight: 700;
    border: none;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease-out;
    margin-top: 20px;

    &:hover {
        background-color: #215357;
    }
`;

const headers = [
    { name: 'Exam Code', align: 'left' },
    { name: 'Exam Name', align: 'left' },
    { name: 'Price', align: 'right' },
    { name: 'Action', align: 'right' },
];
const Cart = () => {
    const navigate = useNavigate();
    const { isAuth } = useAuth();
    const { cartItems } = localStorageVars;
    const [cart, setCart] = useState(() => {
        const cartString = localStorage.getItem(cartItems);
        return cartString ? JSON.parse(cartString) : [];
    });

    useEffect(() => {
        localStorage.setItem(cartItems, JSON.stringify(cart));
    }, [cart, cartItems]);

    const removeCartItem = (idToRemove: string) => {
        setCart((prevCart: any) =>
            prevCart.filter((item: any) => item._id !== idToRemove),
        );
    };

    const totalPrice = cart.reduce(
        (total: number, item: any) => total + item.amount,
        0,
    );

    const handleCheckout = async () => {
        try {
            const response = await ApiService.post(
                '/api/payment/create-paypal-order',
                { items: cart },
                { useAuthorization: true },
            );
            if (response.status === 200) {
                const { links } = response.data;
                if (!Array.isArray(links)) return;
                let target = links.find((link) => link.rel === 'approval_url');
                if (target) window.open(target.href);
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <div className="p-5">
            <h1 className="mb-5 text-4xl font-extrabold">Cart</h1>
            <table className="w-full border-collapse">
                <thead>
                    <tr>
                        {headers.map((item: any) => (
                            <TableHeader textAlign={item.align}>
                                {item.name}
                            </TableHeader>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {cart.map((item: any) => (
                        <TableRow key={item._id}>
                            <TableCell textAlign="left">{item.sku}</TableCell>
                            <TableCell textAlign="left">{item.name}</TableCell>
                            <TableCell textAlign="right">
                                ${item.amount}
                            </TableCell>
                            <TableCell textAlign="right">
                                <RemoveButton
                                    onClick={() => removeCartItem(item._id)}
                                >
                                    <BiTrash />
                                </RemoveButton>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TotalPriceRow>
                        <TotalPriceCell colSpan={3}>
                            Total Price:
                        </TotalPriceCell>
                        <TotalPriceCell>${totalPrice}</TotalPriceCell>
                    </TotalPriceRow>
                </tbody>
            </table>
            <div className="flex justify-end">
                <CheckoutButton
                    disabled={cart?.length === 0}
                    onClick={handleCheckout}
                >
                    Checkout
                </CheckoutButton>
            </div>
        </div>
    );
};

export default Cart;
