import { styled } from 'styled-components';

const NavbarWrapper = styled.nav`
    height: 5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, #1e3783, rgb(255 248 248) 100%);
    @media (max-width: 956px) {
        padding: 0 1rem;
    }
`;

const NavbarInnerWidth = styled.div`
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const LogoWrapper = styled.div`
    cursor: pointer;
`;

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 956px) {
        display: none;
    }
`;
const LinkMenu = styled.a`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1em;
    cursor: pointer;
`;

const MenuButton = styled.div`
    display: none;
    border: 1px solid #000050;
    border-radius: 5px;
    padding: 1px;
    @media (max-width: 956px) {
        display: block;
    }
`;

export {
    NavbarWrapper,
    LogoWrapper,
    MenuButton,
    LinkWrapper,
    LinkMenu,
    NavbarInnerWidth,
};
