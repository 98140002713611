import React from 'react';
import { styled } from 'styled-components';
import { FaThumbsUp } from 'react-icons/fa';
import YellowText from './BlueText';
import { appName, color } from '../../common/constant';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 768px) {
        padding: 0rem 1rem;
    }
`;
const Header = styled.h1`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 2em;

    @media screen and (max-width: 768px) {
        text-align: center;
        font-size: 1.5em;
    }
`;
const Desc = styled.h3`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 0.9em;
    text-align: center;
    width: 80%;
`;
const CardContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    grid-row-gap: 3rem;
    justify-items: center;
    padding: 20px;
`;
const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 20rem;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    border: 1px solid rgba(224, 224, 224, 0.652);
`;
const Icon = styled.div`
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Title = styled.h2`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1.2em;
    text-align: center;
`;
const CardDesc = styled.h3`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 0.8em;
    text-align: center;
    padding: 20px 0;
`;

const ChooseSection = () => {
    return (
        <Container>
            <Header>
                Why People Always Choose <YellowText>{appName}</YellowText>
            </Header>
            <Desc>
                We have been helping students pass their certification tests
                from the past years. We take pride in helping them achieve
                outstanding outcomes, scoring over 95% on their IT Exams, and
                have a track record of over 80,000 satisfied students.{' '}
                <strong>{appName}</strong>, a dependable source, has been
                crucial to their success. Our services are accessible to
                students all around the world and are available globally. Every
                country can use and benefit from our practice test questions,
                PDF Dumps, and study guide. To ensure that aspiring
                professionals worldwide have easy access to dependable help and
                thorough, effective exam preparation, we work hard to meet their
                needs.
            </Desc>
            <CardContainer>
                <Card>
                    <Icon>
                        <FaThumbsUp size={30} color={color} />
                    </Icon>
                    <Title>Quality</Title>
                    <CardDesc>We don’t compromise on Quality</CardDesc>
                </Card>
                <Card>
                    <Icon>
                        <FaThumbsUp size={30} color={color} />
                    </Icon>
                    <Title>Success</Title>
                    <CardDesc>
                        {appName} is the only Key to Success in IT
                        Certifications
                    </CardDesc>
                </Card>
                <Card>
                    <Icon>
                        <FaThumbsUp size={30} color={color} />
                    </Icon>
                    <Title>24/7 Support</Title>
                    <CardDesc>
                        We provide 24/7 customer support services to help our
                        beloved customers by solving their problems
                    </CardDesc>
                </Card>
            </CardContainer>
        </Container>
    );
};

export default ChooseSection;
