import React from 'react';
import { BsSearch } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { API } from '../common/api-end-point';
import PageTitle from '../components/PageTitle';
import SearchParser from '../components/searchParser/SearchParser';
import { ApiService } from '../services/ApiService';

const SearchContainer = styled.div`
    width: 90%;
    height: 3rem;
    padding: 5px;
    margin-top: 20px;
    background: #ffffff;
    box-shadow: -3px 5px 6px #6dbcc7;
    border-radius: 10px;
    border: 1px solid #0994e542;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const SearchInput = styled.input`
    border: none;
    outline: none;
    background: none;
    font-size: 16px;
    width: 90%;
`;

const SearchButton = styled.button`
    padding: 10px 16px;
    font-size: 0.9em;
    border: none;
    background: #1e3783;
    color: white;
    border-radius: 10px;
    cursor: pointer;
`;

const Search = () => {
    const [q, setQ] = React.useState('');
    const navigate = useNavigate();

    const [data, setData] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);

    const searchHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        setQ(event.target.value);
    };

    const searchApiHandler = async (): Promise<void> => {
        let url = API.search.get;
        let query = q as string;
        url = url.concat(query.length > 0 ? `?q=${query}` : '');
        const options = { useAuthorization: false };
        try {
            setIsLoading(true);
            const response = await ApiService.get(url, options);
            setData(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const searchNavigate = (): void => {
        searchApiHandler();
    };

    React.useEffect(() => {
        searchApiHandler();
    }, [q]);

    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const query = searchParams.get('q');
        if (query) setQ(query);
    }, []);
    return (
        <>
            <PageTitle title={'Search'} />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '1rem',
                }}
            >
                <SearchContainer>
                    <SearchInput onChange={searchHandler} value={q} />
                    <SearchButton onClick={searchNavigate}>SEARCH</SearchButton>
                </SearchContainer>
            </div>

            {isLoading ? (
                <h1>Loading...</h1>
            ) : (
                <div>
                    <SearchParser
                        name="Vendors"
                        count={data['totalVendors' as keyof object]}
                        key={0}
                        data={data['vendors' as keyof object]}
                        cb={(slug: string, id: string, name: string) =>
                            navigate(`/vendor/${slug}`, {
                                state: { name, slug, id },
                            })
                        }
                    />
                    <SearchParser
                        name="Certificates"
                        count={data['totalCertificates' as keyof object]}
                        key={2}
                        data={data['certificates' as keyof object]}
                        cb={(
                            slug: string,
                            id: string,
                            name: string,
                            vendorName?: string,
                        ) =>
                            navigate(`/certificate/${slug}`, {
                                state: { name, slug, id, vendorName },
                            })
                        }
                    />
                    <SearchParser
                        name="Exams"
                        count={data['totalExams' as keyof object]}
                        key={1}
                        data={data['exams' as keyof object]}
                        cb={(
                            slug: string,
                            id: string,
                            name: string,
                            vendorName?: string,
                            certificateName?: string,
                        ) =>
                            navigate(`/exam/${slug}`, {
                                state: {
                                    name,
                                    id,
                                    slug,
                                    vendorName,
                                    certificateName,
                                },
                            })
                        }
                    />
                </div>
            )}
        </>
    );
};

export default Search;
