import React from 'react';
import { styled } from 'styled-components';
import Point from './Point';
import Book from '../svg/Book';
import YellowText from './BlueText';
import { appName } from '../../common/constant';

const Container = styled.div`
    padding: 5rem 5rem;
    display: flex;
    gap: 2rem;
    margin-top: 5rem;
    position: relative;
    @media screen and (max-width: 768px) {
        padding: 3rem 1rem;
        margin-top: 3rem;
        flex-direction: column;
        align-items: center;
    }
`;
const Background = styled.div`
    position: absolute;
    background: #f4f4f4;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    transform: rotate(-2deg);
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
const Header = styled.h1`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 2em;
    width: 100%;

    @media screen and (max-width: 768px) {
        text-align: center;
        font-size: 1.5em;
    }
`;
const Info = styled.h3`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 0.8em;
    @media screen and (max-width: 768px) {
        text-align: center;
    }
`;
const PointsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const Right = styled.div``;
const BookContainer = styled.div`
    height: 25em;
    width: 20em;
`;
const AssuranceSection = () => {
    return (
        <Container>
            <Background></Background>
            <Left>
                <Header>
                    Most Reliable Ways to Pass{' '}
                    <YellowText>
                        {' '}
                        {appName.replace('Exams', 'Certification')}
                    </YellowText>
                </Header>
                <Info>
                    Unlock success in your IT certification journey with our
                    guide on the most reliable ways to pass exams effortlessly.
                    Whether you're a seasoned professional or just starting your
                    IT career, our comprehensive strategies will help you
                    navigate the challenging terrain of certification exams with
                    confidence. From proven study techniques to practical tips
                    for effective time management, this guide is your key to
                    mastering the material and acing your IT certification
                    exams. Don't leave your success to chance – trust in these
                    reliable methods to propel yourself towards certification
                    success. We work hard to keep our costs low so that everyone
                    may access our products. Offering the most recent question
                    responses keeps our clients informed, which is our unique
                    selling proposition. Delivering high-quality exam materials
                    is our top priority since we want to see you succeed in your
                    endeavors.
                </Info>
                <PointsContainer>
                    <Point
                        number="01"
                        title={`Passing Assurance with ${appName}`}
                        desc="We provide 100% passing assurance in all IT Exams because of our quality of pdf dumps."
                    />
                    <Point
                        number="02"
                        title="Privacy Protection"
                        desc={`With ${appName} Exam Dumps you find your data save and sound in any way.`}
                    />
                </PointsContainer>
            </Left>
            <Right>
                <BookContainer>
                    <Book />
                </BookContainer>
            </Right>
        </Container>
    );
};

export default AssuranceSection;
