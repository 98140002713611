import actions from './action';
import { ApiService } from '../../services/ApiService';
import { API } from '../../common/api-end-point';

const { begin, addSuccess, getSuccess, error } = actions;

export const getAllCertificate = (id: string) => {
    return async (dispatch: any) => {
        dispatch(begin());

        try {
            const response = await ApiService.get(
                API.certificate.get.concat(id),
                {
                    signal: 15000,
                    useAuthorization: false,
                    headers: {},
                },
            );
            const { data } = response;
            dispatch(getSuccess(data.data));
        } catch (err) {
            console.error(err);
            // dispatch(error(err?.message));
        }
    };
};
