import { styled } from 'styled-components';
import { color } from '../common/constant';
import { ButtonProps } from '../types/types';

const Container = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Btn = styled.button`
    height: 50px;
    width: 100%;
    background-color: ${color};
    color: white;
    font-size: 16px;
    font-weight: 700;
    border: none;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease-out;

    &:hover {
        background-color: #6c9a31;
    }
`;

const Button = ({ text, onClick }: ButtonProps) => {
    const handleClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (onClick) {
            onClick(e);
        }
    };
    return (
        <Container>
            <Btn onClick={handleClick}>{text}</Btn>
        </Container>
    );
};

export default Button;
