import { FaBook } from 'react-icons/fa';
import { styled } from 'styled-components';
import { ExamProps } from '../../types/types';
import { useNavigate } from 'react-router-dom';
import { color } from '../../common/constant';

const Container = styled.div<{ primary: boolean }>`
    background: ${({ primary }) => (primary ? '#6589f3' : 'white')};
    width: 15rem;
    padding: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    transition: 0.1s ease-in-out;
    cursor: pointer;
    &:hover {
        background: ${({ primary }) => (primary ? 'lightgray' : 'lightgray')};
    }
`;
const Circle = styled.div<{ primary: boolean }>`
    height: 3.8em;
    width: 3.8em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ primary }): string => (primary ? 'white' : color)};
`;
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    gap: 7px;
`;
const Name = styled.div`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
`;
const Questions = styled.div`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.8em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    color: rgba(0, 0, 0, 0.48);
`;

const Exam = ({
    name,
    questions,
    primary,
    id,
    slug,
    vendorName,
    certificateName,
}: ExamProps) => {
    const navigate = useNavigate();
    return (
        <Container primary={primary}>
            <Circle primary={primary}>
                <FaBook
                    size={24}
                    color={primary ? 'black' : 'white'}
                    style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                />
            </Circle>
            <TextContainer>
                <Name
                    onClick={() => {
                        navigate(`/exam/${slug}`, {
                            state: {
                                name,
                                id,
                                slug,
                                vendorName,
                                certificateName,
                            },
                        });
                    }}
                >
                    {name}
                </Name>
                <Questions>{questions} Question(s)</Questions>
            </TextContainer>
        </Container>
    );
};

export default Exam;
