import React from 'react';
import { styled } from 'styled-components';
import { color } from '../../common/constant';
import { PointProps } from '../../types/types';

const Container = styled.div`
    display: flex;
    gap: 15px;
    align-items: start;
    width: 100%;
`;
const Circle = styled.div`
    background: ${color};
    border-radius: 50%;
    color: white;
    font-size: 1em;
    font-family: 'Poppins';
    font-weight: 400;
    padding: 1rem 1rem;
`;
const TextContainer = styled.div`
    width: 80%;
`;
const Title = styled.h2`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1em;
`;
const Desc = styled.h3`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 0.8em;
`;
const Point = ({ number, title, desc }: PointProps) => {
    return (
        <Container>
            <Circle>{number}.</Circle>
            <TextContainer>
                <Title>{title}</Title>
                <Desc>{desc} </Desc>
            </TextContainer>
        </Container>
    );
};

export default Point;
