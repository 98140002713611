import React from 'react';
import YellowText from './BlueText';
import { styled } from 'styled-components';
import ReviewCard from './ReviewCard';
import { appName } from '../../common/constant';

const Container = styled.div`
    width: 105%;
    height: 100%;
    background: #f4f4f4;
    margin-top: 5rem;
    transform: rotate(-2deg);
`;
const Background = styled.div`
    background: #f4f4f4;
    margin-top: 3.5rem;
    transform: rotate(2deg);
`;
const Header = styled.h1`
    font-family: Poppins;
    font-size: 40px;
    text-align: center;
    font-weight: 700;

    @media (max-width: 475px) {
        font-size: 24px;
    }
`;
const CardContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const CardGrid = styled.div`
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    gap: 3rem;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 1rem;
    }
`;

const ReviewSection = () => {
    return (
        <Container>
            <Background>
                <Header>
                    What Our <YellowText>Students</YellowText> Say?
                </Header>
                <CardContainer>
                    <CardGrid>
                        <ReviewCard
                            review={`“I am really happy tha i have passes my exam with the help of ${appName} Exam Questions. The PDF dumps material was really helpful. I passed my AWS solution architect associate exam at first attempt.”`}
                            name="Wilsan Homee"
                        />
                        <ReviewCard
                            review={`“Recenly I’ve reviewed this web site and I shall be making a purchase for AWS-SOLUTION-ARCHITECT-ASSOCIATE | AWS Certified Solution Architect - Associate one of my friend recommended me to buy dumps from here. My friend Petter passed his ${appName} exam after using these dumps. Wish me best of luck.”`}
                            name="Charliey Johns"
                        />
                    </CardGrid>
                </CardContainer>
            </Background>
        </Container>
    );
};

export default ReviewSection;
