import React, { useEffect, useState } from 'react';
import { BiDownload } from 'react-icons/bi';
import styled from 'styled-components';
import { ApiService } from '../services/ApiService';
import { API } from '../common/api-end-point';

interface Props {
    textAlign: string;
}

const TableHeader = styled.th<Props>`
    padding: 10px;
    border-bottom: 1px solid #ccc;
    text-align: ${(props) => props.textAlign};
    border: 1px solid #ccc;
`;

const TableCell = styled.td<Props>`
    padding: 10px;
    text-align: ${(props) => props.textAlign};
    border: 1px solid #ccc;
`;

const RemoveButton = styled.button`
    background-color: #366d16;
    color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.2s ease-out;
    padding: 0.5em;
    &:hover {
        background-color: #1c3d09;
    }
`;

const headers = [
    { name: 'Exam Code', align: 'left' },
    { name: 'Exam Name', align: 'left' },
    { name: 'Price', align: 'right' },
    { name: 'Action', align: 'right' },
];

const Transactions = () => {
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        const apiCall = async () => {
            try {
                const response = await ApiService.get(API.stripe.transaction, {
                    headers: {},
                    signal: 15000,
                    useAuthorization: true,
                });

                if (response.status === 200) {
                    setTransactions(response.data.purchase_items);
                }
            } catch (error) {
                console.log(error);
                setTransactions([]);
            }
        };

        apiCall();
    }, []);

    return (
        <div className="p-5">
            <h1 className="mb-5 text-4xl font-extrabold">Transactions</h1>
            {
                <table className="w-full border-collapse">
                    <thead>
                        <tr>
                            {headers.map((item: any) => (
                                <TableHeader textAlign={item.align}>
                                    {item.name}
                                </TableHeader>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.length > 0 &&
                            transactions.map((item: any, index) => (
                                <tr key={index}>
                                    <TableCell textAlign="left">
                                        {item?.sku}
                                    </TableCell>
                                    <TableCell textAlign="left">
                                        {item?.name}
                                    </TableCell>
                                    <TableCell textAlign="right">
                                        ${item?.amount}
                                    </TableCell>
                                    <TableCell textAlign="right">
                                        <RemoveButton>
                                            <BiDownload />
                                        </RemoveButton>
                                    </TableCell>
                                </tr>
                            ))}
                    </tbody>
                </table>
            }
        </div>
    );
};

export default Transactions;
