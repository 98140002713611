import { styled } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import {
    AiOutlineSearch,
    AiOutlineShoppingCart,
    AiOutlineMenu,
} from 'react-icons/ai';
import {
    LogoWrapper,
    NavbarWrapper,
    LinkMenu,
    LinkWrapper,
    NavbarInnerWidth,
    MenuButton,
} from '../../styled/navbar';
import useAuth from '../../hooks/useAuth';
import { BiUserCircle } from 'react-icons/bi';
import DropdownMenu from '../dropdown/DropdownMenu';
import React, { useState } from 'react';
import { appName } from '../../common/constant';

const Right = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 956px) {
        display: none;
    }
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 2rem;
    color: #000050;

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;
const LoginBtn = styled.button`
    border: none;
    background: none;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1em;
    cursor: pointer;
`;

const SignUpBtn = styled.button`
    padding: 5px;
    border: 1px solid #000000;
    border-radius: 3px;
    background: none;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1em;
    cursor: pointer;
`;
const DrawerWrapper = styled.div<{ open: boolean }>`
    display: ${({ open }) => (open ? 'block' : 'none')};
`;
const Navbar = () => {
    const navigate = useNavigate();
    const { isAuth } = useAuth();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const drawerHandler = (): void => setIsOpen(!isOpen);

    return (
        <React.Fragment>
            <NavbarWrapper>
                <NavbarInnerWidth>
                    <LogoWrapper>
                        <Link to={'/'}>
                            <Header>{appName}</Header>
                        </Link>
                    </LogoWrapper>
                    <LinkWrapper>
                        <LinkMenu>
                            <Link to="/home" className="link-menu">
                                Home
                            </Link>
                        </LinkMenu>
                        <LinkMenu>
                            <Link to="/vendor" className="link-menu">
                                Vendor
                            </Link>
                        </LinkMenu>
                        <LinkMenu>
                            <Link to="/guarantee" className="link-menu">
                                Guarantee
                            </Link>
                        </LinkMenu>
                        <LinkMenu>
                            <Link to="/contact" className="link-menu">
                                Contact
                            </Link>
                        </LinkMenu>
                    </LinkWrapper>
                    <Right>
                        <AiOutlineSearch
                            size={20}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/search');
                            }}
                        />

                        <AiOutlineShoppingCart
                            size={20}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/cart');
                            }}
                        />

                        {isAuth ? (
                            <div className="flex gap-1">
                                <BiUserCircle size={20} />
                                <DropdownMenu />
                            </div>
                        ) : (
                            <>
                                <LoginBtn
                                    onClick={() => {
                                        navigate('/login');
                                    }}
                                >
                                    Login
                                </LoginBtn>
                                <SignUpBtn
                                    onClick={() => {
                                        navigate('/register');
                                    }}
                                >
                                    Sign Up
                                </SignUpBtn>
                            </>
                        )}
                    </Right>
                    <MenuButton>
                        <AiOutlineMenu
                            size={26}
                            color="#000050"
                            onClick={drawerHandler}
                        />
                    </MenuButton>
                </NavbarInnerWidth>
            </NavbarWrapper>
            <DrawerWrapper open={isOpen}></DrawerWrapper>
        </React.Fragment>
    );
};

export default Navbar;
