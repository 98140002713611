import React from 'react';
import { styled } from 'styled-components';
import SampleQuestions from './SampleQuestions';
import CustomerFeedback from './CustomerFeedback';
import { appName } from '../../common/constant';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5em;
`;
const Title = styled.h5`
    color: #358186;
    font-family: Inter;
    font-weight: 400;
    margin-bottom: 2em;
    border-bottom: 1px solid black;
`;
const Header = styled.h2`
    font-size: 1.3em;
    font-family: Inter;
    font-weight: 700;
    margin-bottom: 1em;
`;
const Desc = styled.p`
    font-size: 0.95em;
    font-family: Inter;
    margin-bottom: 2em;
`;
const Ul = styled.ul`
    padding: 0 2em;
    margin-bottom: 2em;
`;
const Li = styled.li`
    font-size: 0.95em;
    font-family: Inter;
`;
const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 1px;
    border: 1px solid black;
    background-color: black;
    margin-bottom: 2em;
`;
const GridItem = styled.p`
    background-color: #f4f4f4;
    font-size: 0.95em;
    font-family: Inter;
    padding: 1em 0 1em 1em;
`;
const DescriptionSection = () => {
    return (
        <Container>
            <Title>Description</Title>
            <Header>AWS Certified: SAP on AWS - Specialty (PAS-C01)</Header>
            <Desc>
                The AWS Certified: SAP on AWS - Specialty (PAS-C01) exam
                certifies that the candidate has advanced specialist knowledge
                and expertise necessary to design, execute, move, and work SAP
                tasks optimally on AWS. This evaluation validates a person's
                ability to carry out the following tasks:
            </Desc>
            <Ul>
                <Li>
                    Follow the AWS Well-Architected Framework when creating an
                    SAP configuration for the AWS Cloud.
                </Li>
                <Li>
                    Create an SAP setup that works on AWS in accordance with SAP
                    certification and support requirements.
                </Li>
                <Li>Carry out new SAP functions on AWS</Li>
            </Ul>
            <Header>Course Frame for PAS-C01 Exam</Header>
            <Ul>
                <Li>Design of SAP jobs on AWS 30%</Li>
                <Li>Implementation of SAP responsibilities on AWS 24%</Li>
                <Li>Migration of SAP responsibilities to AWS 26%</Li>
                <Li>
                    Operation and support of SAP responsibilities on AWS 20%
                </Li>
            </Ul>
            <Header>Official Exam Details:</Header>
            <Grid>
                <GridItem>Total Exam Questions:</GridItem>
                <GridItem>50 Inquiries</GridItem>
                <GridItem>Passing Criteria for the Exam:</GridItem>
                <GridItem>750</GridItem>
                <GridItem>Type of questions/ questions format:</GridItem>
                <GridItem>
                    Multiple decisions (single/numerous), True/False
                </GridItem>
            </Grid>
            <Header>
                The Best Method for Passing the PAS-C01 Exam Right Now!
            </Header>
            <Desc>
                The best strategy for passing the PAS-C01 exam with a good score
                is to use PAS-C01 practice Questions. Using these practical test
                questions, you'll progress all of the material from many angles,
                and when the time comes for exams, everything will come together
                in one amazing package! Remember that these confidence-boosting
                activities, such as practice exams, provide people with
                assistance and integrate useful information into the real test's
                questions. This is the major reason we advise you to acquire
                exam dumps from {appName}. For best outcomes, check through many
                dumps regions to have a better understanding of the SAP
                Certified specialized Associate exam. One of the most amazing
                dump sites for SAP Certified specialty Associate exam
                preparation is the {appName}. They provide updated exam
                preparation courses, pdf content, and a free practice test. Your
                performance on a certified test is best represented by the demo
                practice test.
            </Desc>
            <Header>
                AWS PAS-C01 PDF Guide - Enhances Your Exam Preparation Skills
            </Header>
            <Desc>
                Passing the PAS-C01 exam nowadays is essential for establishing
                a successful career in the field. Candidates must obtain the
                Original PAS-C01 Exam Dumps from {appName} in order to
                successfully complete the requirements for the AWS Certified SAP
                on AWS - Specialty certification. Because the Cisco PAS-C01
                dumps pdf is so well-liked, you won't have any problems when
                getting ready for the AWS Certified Specialty test. Everyone
                wants to finish all prerequisites for the Cisco PAS-C01 exam
                easily and quickly while studying for it. To obtain your PAS C01
                certification, purchase the dumps4download PAS C01 study guide.
            </Desc>
            <Header>
                High-Quality AWS PAS-C01 Exam Dumps for {appName} Web Services
                Certifications
            </Header>
            <Desc>
                The greatest pdf dumps for the PAS-C01 exam are provided by
                {appName}, which has a 100% first-time pass record. Our exam
                questions also help candidates understand the testing
                environment. Make sure you have properly organized and adjusted
                the test materials before you take the exam. You may evaluate
                your ability to estimate how much effort you should put in to
                pass the real certification test by periodically practicing the
                exam.
            </Desc>
            <Header>
                To Evaluate The Caliber Of The Provided Content, Attempt Our
                Free PAS-C01 Example Questions.
            </Header>
            <Desc>
                Most candidates believe that studying for the exam is superior
                to just trusting what is heard. Since we are aware of your
                concerns, we provide free access to sample exam dumps. Don't
                only rely on words; try out these test dumps right away.
            </Desc>
            <Header>Get IT Specialists' Help Around The Clock.</Header>
            <Desc>
                It would be our pleasure to answer any inquiries you may have.
                The team of IT experts is always accessible to help if you are
                unclear or have questions regarding PAS-C01. As a result, if you
                ever need help or have any questions, feel free to contact our
                bright and skilled assistance staff.
            </Desc>
            <SampleQuestions />
            <CustomerFeedback />
        </Container>
    );
};

export default DescriptionSection;
