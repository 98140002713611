import React from 'react';
import { styled } from 'styled-components';
import { DividerProps } from '../types/types';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
`;
const Divider = ({ children }: DividerProps) => {
    return <Container>{children}</Container>;
};

export default Divider;
