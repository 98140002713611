const actions = {
    BEGIN: 'BEGIN',
    ERR: 'ERR',

    ADD_SUCCESS: 'ADD_SUCCESS',
    GET_SUCCESS: 'GET_SUCCESS',

    begin: () => {
        return {
            type: actions.BEGIN,
        };
    },

    addSuccess: (data: any) => {
        return {
            type: actions.ADD_SUCCESS,
            data,
        };
    },

    getSuccess: (data: any) => {
        return {
            type: actions.GET_SUCCESS,
            data,
        };
    },

    error: (err: any) => {
        return {
            type: actions.ERR,
            err,
        };
    },
};

export default actions;
