import React from 'react';
import { styled } from 'styled-components';
import Item from './Item';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4em;
    gap: 2em;
`;
const Header = styled.h1`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 1.5em;
`;
const Info = styled.h3`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.9em;
`;
const ListContainer = styled.div`
    display: flex;
    gap: 1em;
`;
const Items = styled.div`
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
`;
const DescList = styled.ul`
    width: 45%;
`;
const Ul = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
`;
const Li = styled.li`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1em;
    &:before {
        content: '✓';
    }
`;
const items = [
    {
        title: 'PDF',
        demo: true,
        price: 69,
    },
    {
        title: 'Test Engine',
        demo: true,
        price: 89,
    },
    {
        title: 'PDF + Test Engine',
        demo: false,
        price: 109,
    },
];
const TopSection = () => {
    return (
        <Container>
            <Header>DOWNLOAD AXS-C01 QUESTIONS PDF</Header>
            <Info>
                Download AXS-C01 dumps PDF file that contain real exam question
                answers available here on Cisco dump. If you're seeking to boost
                your career prospects by passing the AWS Certified Alexa Skill
                Builder-Specialty exam, our AXS-C01 questions answers is the
                perfect study material resource. With our comprehensive AXS-C01
                Study guide, you'll have everything you need to prepare for the
                AWS Certified Alexa Skill Builder-Specialty exam and succeed.
                Download it today and get started on your path to success!
            </Info>
            <ListContainer>
                <Items>
                    {items.map((item, i) => {
                        return (
                            <Item
                                key={i}
                                title={item.title}
                                demo={item.demo}
                                price={item.price}
                            />
                        );
                    })}
                </Items>
                <DescList>
                    <Ul>
                        <Li>65 Questions with Answers</Li>
                        <Li>Updating Date : March 26, 2023</Li>
                        <Li>Just 1 day Study Required to Pass Exam</Li>
                        <Li>100% Passing Assurance</Li>
                        <Li>100% Money Back Guarantee</Li>
                        <Li>Free 3 Months Updates</Li>
                    </Ul>
                </DescList>
            </ListContainer>
        </Container>
    );
};

export default TopSection;
