import React from 'react';
import { styled } from 'styled-components';
import { ResultProps } from '../../types/types';

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 0.7em;
`;
const TextContainer = styled.div``;
const Number = styled.h3`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 2em;
`;
const Text = styled.p`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.8em;
`;
const Result = ({ icon, number, text }: ResultProps) => {
    return (
        <Container>
            {icon}
            <TextContainer>
                <Number>{number}</Number>
                <Text>{text}</Text>
            </TextContainer>
        </Container>
    );
};

export default Result;
