import React from 'react';
import { styled } from 'styled-components';
import Stars from '../Stars';
import Flag from 'react-flagkit';
import { ReviewProps } from '../../types/types';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
`;
const Info = styled.div`
    display: flex;
    align-items: center;
    gap: 1em;
    font-size: 0.9em;
    font-family: Inter;
`;
const Text = styled.p`
    font-size: 0.9em;
    font-family: Inter;
`;
const Review = ({ flag, name, date, review }: ReviewProps) => {
    return (
        <Container>
            <Info>
                <Flag country={flag} />
                <strong> {name}</strong> {date}
                <Stars />
            </Info>
            <Text>{review}</Text>
        </Container>
    );
};

export default Review;
