import React from 'react';
import { styled } from 'styled-components';
import CommentInput from './CommentInput';
import Divider from '../Divider';
import Stars from '../Stars';
import CommentTextarea from './CommentTextarea';

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    background: white;
    padding: 3em 0;
`;
const Form = styled.form`
    width: 85%;
    padding: 3em 2em;
    background: rgba(217, 217, 217, 0.55);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 2em;
`;
const Title = styled.h3`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 1.5em;
`;
const Desc = styled.h4`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.9em;
`;
const FeedbackContainer = styled.div`
    display: flex;
    gap: 3em;
`;
const Feedback = styled.p`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.9em;
`;
const Button = styled.button`
    font-size: 0.9em;
    color: white;
    width: 10em;
    padding: 1em 2em;
    border: none;
    background: #358186;
    border-radius: 10px;
    cursor: pointer;
`;
const CommentSection = () => {
    return (
        <Container>
            <Form>
                <Title>Leave a comment</Title>
                <Desc>
                    Your email address will not be published. Required fields
                    are marked *
                </Desc>
                <Divider>
                    <CommentInput placeholder="Name" />
                    <CommentInput placeholder="Email" />
                </Divider>
                <CommentTextarea placeholder="Comment" />
                <FeedbackContainer>
                    <Feedback>Rating / Feedback About This Exam</Feedback>
                    <Stars />
                </FeedbackContainer>
                <Button>Comment</Button>
            </Form>
        </Container>
    );
};

export default CommentSection;
