import { Route, Routes } from 'react-router-dom';
import Login from './screens/Login';
import Register from './screens/Register';
import Exams from './screens/Exams';
import Guarantee from './screens/Guarantee';
import Contact from './screens/Contact';
import Home from './screens/Home';
import ExamDetail from './screens/ExamDetail';
import Courses from './screens/Courses';
import Layout from './layout/Layout';
import Certificate from './screens/Certificate';
import Cart from './screens/Cart';
import useAuth from './hooks/useAuth';
import Transactions from './screens/Transactions';
import Payment from './screens/Payment';
import PrivateRoutes from './middleware/PrivateRoutes';
import Vendor from './screens/Vendor';
import Search from './screens/Search';

function App() {
    const { isAuth } = useAuth();

    return (
        <Routes>
            <Route path="/" element={<Layout children={<Home />} />} />
            <Route path="/home" element={<Layout children={<Home />} />} />
            {!isAuth && <Route path="/register" element={<Register />} />}
            {!isAuth && <Route path="/login" element={<Login />} />}
            <Route path="/vendor" element={<Layout children={<Vendor />} />} />
            <Route
                path="/vendor/:slug"
                element={<Layout children={<Certificate />} />}
            />
            <Route
                path="/certificate/:slug"
                element={<Layout children={<Exams />} />}
            />
            <Route
                path="/guarantee"
                element={<Layout children={<Guarantee />} />}
            />
            <Route
                path="/contact"
                element={<Layout children={<Contact />} />}
            />
            <Route
                path="/exam/:slug"
                element={<Layout children={<ExamDetail />} />}
            />
            <Route
                path="/courses"
                element={<Layout children={<Courses />} />}
            />
            <Route
                path="/cart"
                element={
                    <PrivateRoutes>
                        <Layout children={<Cart />} />
                    </PrivateRoutes>
                }
            />
            <Route
                path="/transactions"
                element={<Layout children={<Transactions />} />}
            />
            <Route
                path="/success"
                element={<Layout children={<Payment />} />}
            />
            <Route path="/search" element={<Layout children={<Search />} />} />
        </Routes>
    );
}

export default App;




