import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { color } from '../../common/constant';
import ListMenu from '../ListMenu';
const TitleMainWrapper = styled.div`
    display: flex;
    justify-content: center;
    background: ${color};
`;
const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 90%;
`;
const Title = styled.h1`
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    grid-row-gap: 3rem;
    padding: 5rem;
    background: #f4f4f4;
`;

const SearchParser = ({
    name,
    count,
    data,
    cb,
}: {
    name: string;
    count: number;
    data: any[];
    cb: (
        slug: string,
        id: string,
        name: string,
        vendorName?: string,
        certificateName?: string,
    ) => void;
}) => {
    return (
        <div>
            <TitleMainWrapper>
                <TitleWrapper>
                    <Title>{name}</Title>
                    <Title>{count}</Title>
                </TitleWrapper>
            </TitleMainWrapper>

            <Container>
                {Array.isArray(data) && data.length > 0 ? (
                    data.map((item) => {
                        return (
                            <ListMenu
                                id={''}
                                name={item?.name}
                                slug={''}
                                primary={false}
                                onNavigate={() => {
                                    cb(
                                        item?.slug,
                                        item?._id,
                                        item?.name,
                                        item?.vendor?.name,
                                        item?.certificate?.name,
                                    );
                                }}
                            />
                        );
                    })
                ) : (
                    <h1>Not Found</h1>
                )}
            </Container>
        </div>
    );
};

export default SearchParser;
