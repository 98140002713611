import React from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;
const Title = styled.h1`
    font-size: 2em;
    font-family: Inter;
    font-weight: 700;
    text-align: center;
`;
const Heading = styled.h3`
    font-family: Inter;
    font-weight: 700;
    font-size: 1.4em;
    margin-bottom: 1em;
`;
const Answer = styled.p`
    color: #414042;
    font-size: 0.9em;
    font-family: Inter;
    font-weight: 700;
    margin-bottom: 2em;
`;
const Ul = styled.ul`
    margin-top: 1em;
    list-style: none;
`;
const Li = styled.li`
    font-weight: 400;
    font-family: Inter;
    color: black;
`;
const Bold = styled.p`
    color: black;
    font-size: 1em;
    font-family: Inter;
    font-weight: 700;
    margin-top: 1em;
`;
const SampleQuestions = () => {
    return (
        <Container>
            <Title>Sample Questions</Title>
            <Heading>Question 1</Heading>
            <Answer>
                A company is planning to move to AWS. The company wants to set
                up sandbox and test environments on AWS to perform proofs of
                concept (POCs) Development and production environments will
                remain on premises until the POCs are completed. At the
                company's on-premises location SAProuter is installed on the
                same server as SAP Solution Manager. The company uses SAP
                Solution Manager to monitor the entire landscape The company
                uses SAP router to connect to SAP Support The on-premises SAP
                Solution Manager instance must monitor the performance and
                server metrics of the newly created POC systems on AWS. The
                existing SAP router must be able to report any issues to SAP
                What should an SAP solutions architect do to set up this hybrid
                infrastructure MOST cost-effectively''
                <Ul>
                    <Li>
                        A. Install a new SAP Solution Manager instance and a new
                        SAP router instance m the AWS environment Connect the
                        POC systems to these new instances Use these new
                        instances m parallel with the on-premises SAP Solution
                        Manager instance and the on-premises SAP router instance
                    </Li>
                    <Li>
                        B. Install a new SAP Solution Manager instance and a new
                        SAP router instance in the AWS environment install the
                        Cisco CloudWatch agent on all on-premises instances Push
                        the monitoring data to the new SAP Solution Manager
                        instance Connect ail on-premises systems and POC systems
                        on AWS to the new SAP Solution Manager instance and the
                        new SAP router instance Remove the on-premises SAP
                        Solution Manager instance and the on-premises SAP router
                        instance Use the new instances on AWS
                    </Li>
                    <Li>
                        C. Use AWS Site-to-Site VPN to connect the on-premises
                        network to the AWS environment Connect the POC systems
                        on AWS to the on-premises SAP Solution Manager instance
                        and the on-premises SAP router instance
                    </Li>
                    <Li>
                        D. Add the POC systems on AWS to the existing SAP
                        Transport Management System that is configured m the
                        on-premises SAP systems
                    </Li>
                </Ul>
                <Bold>ANSWER : C</Bold>
            </Answer>

            <Heading>Question 2</Heading>
            <Answer>
                A company is running SAP ERP Central Component (SAP ECC) with a
                Microsoft SQL Server database on AWS A solutions architect must
                attach an additional 1 TB Cisco Elastic Block Store (Cisco EBS)
                volume. The company needs to write the SQL Server database
                backups to this EBS volume before moving the database backups to
                Cisco S3 for long-term storage. Which EBS volume type will meet
                these requirements MOST cost-effectively?
                <Ul>
                    <Li>A. Throughput Optimized HDD (st1)</Li>
                    <Li>B. Provisioned IOPS SSD (io3)</Li>
                    <Li>C. General Purpose SSD (gp3)</Li>
                    <Li>D. Cold HDD (sc1)</Li>
                </Ul>
                <Bold>ANSWER : B</Bold>
            </Answer>
            <Heading>Question 3</Heading>
            <Answer>
                A company uses an SAP application that runs batch jobs that ate
                performance sensitive. The batch jobs can be restarted safely
                The SAP application has sot application servers. The SAP
                application functions reliability as long as the SAP application
                availability remains greater than 60%. The company wants to
                migrate the SAP application to AWS. The company is using a
                duster with two Availability Zones How should the company
                distribute the SAP application servers to maintain system
                reliability?
                <Ul>
                    <Li>
                        A. Distribute the SAP application servers equally across
                        three partition placement groups
                    </Li>
                    <Li>
                        B. Distribute the SAP application servers equally across
                        three Availability Zones
                    </Li>
                    <Li>
                        C. Distribute the SAP application servers equally across
                        two Availability Zones
                    </Li>
                    <Li>
                        D. Create an Cisco EC2 Auto Scaling group across two
                        Availability Zones Set a minimum capacity value of 4
                    </Li>
                </Ul>
                <Bold>ANSWER : B</Bold>
            </Answer>
            <Heading>Question 4</Heading>
            <Answer>
                A company that has SAP workloads on premises plans to migrate an
                SAP environment to AWS. The company is new to AWS and has no
                poor setup. The company has the following requirements - The
                application server and database server must be placed in
                isolated network configurations • SAP systems must be accessible
                to the on-premises end users over the internet • The cost of
                communications between the application server and the database
                server must be minimized Which combination of steps should an
                SAP solutions architect take to meet these requirements? (Select
                TWO.)
                <Ul>
                    <Li>
                        A. Set up an AWS Site-to-Site VPN connection between the
                        company's on-premises network and AWS
                    </Li>
                    <Li>
                        B. Separate the application server and the database
                        server by using different VPCs
                    </Li>
                    <Li>
                        C. Separate the application server and the database
                        server by using different subnets and network security
                        groups within the same VPC
                    </Li>
                    <Li>
                        D. Set up an AWS Direct Connect connection with a
                        private VIF between the company's on-premises network
                        and AWS
                    </Li>
                </Ul>
                <Bold>ANSWER : C,D</Bold>
            </Answer>
        </Container>
    );
};

export default SampleQuestions;
