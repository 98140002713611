import React from 'react';
import { styled } from 'styled-components';
import { ReviewCardProps } from '../../types/types';
import Stars from '../Stars';

const Container = styled.div`
    width: 100%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: relative;
`;
const ReviewText = styled.h4`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 0.8em;
`;
const Name = styled.h3`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 0.9em;
`;
const Student = styled.h4`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 0.8em;
`;

const ReviewCard = ({ review, name }: ReviewCardProps) => {
    return (
        <Container>
            <ReviewText>{review}</ReviewText>
            <Name>
                {name}
                <Student>Student</Student>
            </Name>
            <Stars />
        </Container>
    );
};

export default ReviewCard;
