import PageTitle from '../components/PageTitle';
import { styled } from 'styled-components';
import { appName } from '../common/constant';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 0;
    flex-direction: column;
    background: #f4f4f4;
    @media screen and (max-width: 768px) {
        padding: 1rem 0;
    }
`;
const Para = styled.p`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1em;
    color: #868686;
    width: 80%;
`;
const Li = styled.li`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1em;
    color: #868686;
    margin: 5px 0;
`;
const Guarantee = () => {
    return (
        <>
            <PageTitle title={'Our Guarantee'} />
            <Container>
                <Para>
                    Welcome to {appName}, where your success is our priority. We
                    are confident in the quality of our exam tips and the value
                    they bring to your journey. To ensure your peace of mind, we
                    proudly offer the following guarantee:
                </Para>
                <ol style={{ width: '75%', listStyleType: 'number' }}>
                    <Li>
                        <h2>
                            <strong>Quality You Can Trust</strong>
                        </h2>
                        <p>
                            We stand by the quality of our exam tips. If you
                            find any content that doesn't meet your
                            expectations, we are committed to addressing your
                            concerns promptly.
                        </p>
                    </Li>
                    <Li>
                        <h2>
                            <strong>Up-to-Date Material</strong>
                        </h2>
                        <p>
                            Our content is regularly updated to align with the
                            latest IT industry standards. Rest assured, you'll
                            have access to the most current and relevant
                            information for your exams.
                        </p>
                    </Li>
                    <Li>
                        <h2>
                            <strong>Dedicated Support</strong>
                        </h2>
                        <p>
                            Need assistance or clarification? Our support team
                            is here for you. Feel free to reach out with any
                            questions, and we'll strive to provide the guidance
                            you need.
                        </p>
                    </Li>
                    <Li>
                        <h2>
                            <strong>Money-Back Guarantee</strong>
                        </h2>
                        <p>
                            Your satisfaction is paramount. If, for any reason,
                            you are not satisfied with our exam tips within 30
                            days of your purchase, we offer a full refund—no
                            questions asked.
                        </p>
                    </Li>
                    <Li>
                        <h2>
                            <strong>Secure Payment</strong>
                        </h2>
                        <p>
                            Your transactions are secure with us. We use
                            industry-standard encryption to protect your
                            personal and financial information, ensuring a safe
                            and worry-free experience
                        </p>
                    </Li>
                    <Li>
                        <h2>
                            <strong>Continuous Improvement</strong>
                        </h2>
                        <p>
                            We are committed to continuously improving our
                            resources and services. Your feedback is invaluable,
                            and we welcome any suggestions to enhance your
                            learning experience.
                        </p>
                    </Li>
                </ol>
                <Para>
                    At ITExamsTips, we believe in your success. Our guarantee
                    reflects our commitment to providing you with the best tools
                    and support on your journey to certification excellence.
                </Para>
                <Para>
                    Feel free to contact us if you have any questions or
                    concerns. Your success is our success.
                </Para>
            </Container>
        </>
    );
};

export default Guarantee;
