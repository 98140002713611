import React from 'react';
import { styled } from 'styled-components';
import { appName } from '../../common/constant';
import Review from './Review';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;
const Title = styled.h4`
    color: #245b5f;
    font-family: Inter;
    font-weight: 400;
    font-size: 1em;
    text-align: center;
    margin-bottom: 1em;
`;
const Header = styled.h1`
    font-family: Inter;
    font-weight: 700;
    font-size: 2em;
    text-align: center;
    margin-bottom: 1em;
`;
const Reviews = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
`;
const reviews = [
    {
        flag: 'MX',
        name: 'Miguel Gomez',
        date: 'May 25, 2023',
        review: `Thanks to ${appName}, I was able to pass the DVA-C01 certification exam with flying colors. The study material is comprehensive and easy to follow, and the exam dumps were very helpful in preparing me for the actual exam.`,
    },
    {
        flag: 'CA',
        name: 'Fabien Dubois',
        date: 'May 24, 2023',
        review: `I have been using ${appName} for my AWS certification exam preparation for years and I never disappointed. Thank you so much ${appName} for providing me reliable, authentic and up-to-date study material`,
    },
    {
        flag: 'CL',
        name: 'Matteo Rossi',
        date: 'May 24, 2023',
        review: `If you want to pass the DVA-C01 certification exam, then ${appName} is the website for you. The study material is exceptional, and the exam dumps were spot on. Thanks to them, I passed the exam with a 96% score.`,
    },
    {
        flag: 'AR',
        name: 'Eunice Kim',
        date: 'May 23, 2023',
        review: `${appName} has been my go-to for AWS certification exams for the past 3 years. I recently passed the AWS Certified Developer - Associate exam with their study material. I highly recommend ${appName} for anyone looking to pass their AWS exams with ease.`,
    },
    {
        flag: 'CO',
        name: 'Benjamin',
        date: 'May 23, 2023',
        review: `Passed my DVA-C01 - AWS Certified Developer Associate certification exam today with 92% marks. Studied using the dumps at ${appName}. Highly recommended to all.`,
    },
];
const CustomerFeedback = () => {
    return (
        <Container>
            <Title>CUSTOMERS FEEDBACK</Title>
            <Header>What our clients say about DVA-C01 exam</Header>
            <Reviews>
                {reviews.map((item, i) => {
                    return (
                        <Review
                            key={i}
                            flag={item.flag}
                            name={item.name}
                            date={item.date}
                            review={item.review}
                        />
                    );
                })}
            </Reviews>
        </Container>
    );
};

export default CustomerFeedback;
