import React from 'react';
import { FaPercentage, FaQuestion } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';
import { styled } from 'styled-components';
import Result from './Result';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    padding: 1em 0;
`;
const Header = styled.h1`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 2em;
    text-align: center;
`;
const ResultContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5em 0 2em 0;
    gap: 3em;
`;

const result = [
    {
        icon: <HiUserGroup size={50} color="#358186" />,
        number: '45',
        text: 'Students Passed',
    },
    {
        icon: <FaPercentage size={50} />,
        number: '95%',
        text: 'Average Marks',
    },
    {
        icon: <FaQuestion size={50} color="#358186" />,
        number: '98%',
        text: 'Exact Questions',
    },
];
const ResultSection = () => {
    return (
        <Container>
            <Header>Last Week Result</Header>
            <ResultContainer>
                {result.map((item, i) => {
                    return (
                        <Result
                            key={i}
                            icon={item.icon}
                            number={item.number}
                            text={item.text}
                        />
                    );
                })}
            </ResultContainer>
        </Container>
    );
};

export default ResultSection;
