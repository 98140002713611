import { useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import { styled } from 'styled-components';
import Exam from '../components/exam/Exam';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExam } from '../redux/exam/actionsCreator';
import { IExam } from '../interface/interface';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    grid-row-gap: 3rem;
    padding: 5rem;
    background: #f4f4f4;
`;

const Exams = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { name, id } = state;

    const { isLoading, exam } = useSelector((_state: any) => ({
        isLoading: _state?.exam?.loading,
        exam: _state?.exam?.exam,
    }));

    useEffect(() => {
        dispatch(getAllExam(id) as any);
    }, [name, id, dispatch]);

    return (
        <>
            <PageTitle title={name} />
            <Container>
                {isLoading ? (
                    <h1>Loading...</h1>
                ) : (
                    [...exam].map((item: IExam, i) => {
                        return (
                            <Exam
                                key={i}
                                id={item._id}
                                slug={item.slug}
                                name={item.name}
                                vendorName={state?.vendorName}
                                certificateName={name}
                                questions={item.number_of_question}
                                primary={(i + 1) % 2 === 0}
                            />
                        );
                    })
                )}
            </Container>
        </>
    );
};

export default Exams;
