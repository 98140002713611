import React from 'react';
import { styled } from 'styled-components';
import { appName, color } from '../common/constant';
import { FormProps } from '../types/types';

const FormContainer = styled.form`
    height: max-content;
    width: 60%;
    border-radius: 20px;
    box-shadow: 10px 9px 17px 0px #00000040;
    background: #ffffff;
    position: relative;
    border: 1px solid rgba(224, 224, 224, 0.652);
    overflow: hidden;

    @media screen and (max-width: 768px) {
        width: 85%;
    }
`;
const Ellipse = styled.div`
    position: absolute;
    top: -5%;
    right: 0%;
    background: ${color};
    width: 150px;
    height: 150px;
    border-radius: 0 0 0 150px;
`;
const TopDiv = styled.div`
    padding: 2rem 1rem;
`;
const SignIn = styled.p`
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 1.5rem;
`;
const BottomDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    gap: 10px;
`;
const Terms = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #868686;
`;
const LinksContainer = styled.div`
    width: 100%;
    height: 15px;
    display: flex;
    justify-content: space-around;
    gap: 20px;
`;
const Link = styled.a`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: rgba(75, 85, 207, 0.76);
    cursor: pointer;
    transition: font-size 0.3s ease-in-out;
    &:hover {
        font-size: 15px;
    }
`;
const CopyrightContainer = styled.div``;
const Copyright = styled.p`
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 11px;
    text-align: center;
    color: #868686;
`;

const Form = ({ children, title }: FormProps) => {
    const currentYear = new Date().getFullYear();
    return (
        <FormContainer>
            <Ellipse />
            <TopDiv>
                <SignIn>{title}</SignIn>
            </TopDiv>
            {children}
            <BottomDiv>
                <Terms>
                    By signing in you agree to {appName} conditions of use.
                    Please also see our Privacy Notice.
                </Terms>
                <LinksContainer>
                    <Link>Privacy Notice</Link>
                    <Link>Condition Of Use</Link>
                </LinksContainer>
                <CopyrightContainer>
                    <Copyright>
                        &copy; {currentYear}, {appName}
                    </Copyright>
                </CopyrightContainer>
            </BottomDiv>
        </FormContainer>
    );
};

export default Form;
