import React from 'react';
import { styled } from 'styled-components';
import { InfoProps } from '../types/types';

const Container = styled.div`
    height: 5rem;
    width: 20rem;
    display: flex;
    align-items: center;
`;
const Circle = styled.div`
    height: 3.8em;
    width: 3.8em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.color};
`;
const AddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    gap: 7px;
`;
const Name = styled.p`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 1rem;
    margin-left: 15px;
`;
const Desc = styled.p`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.8rem;
    margin-left: 15px;
`;
const Info = ({ color, name, desc, icon }: InfoProps) => {
    return (
        <Container>
            <Circle color={color}>{icon}</Circle>
            <AddressContainer>
                <Name>{name}</Name>
                <Desc>{desc}</Desc>
            </AddressContainer>
        </Container>
    );
};

export default Info;
