const vendorId: string = '6534c57bf2f3e6001bd20ac8';

const localStorageVars = {
    token: 'userToken',
    cartItems: 'cartItems',
    clientSecret: 'clientSecret',
};

const supportEmail: string = 'support@itexamstips.com';
const domain: string = 'itexamstips.com';
const appName: string = 'IT Exams Tips';

const color = '#1e3783';

export { vendorId, localStorageVars, color, domain, supportEmail, appName };
