import { useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import { styled } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCertificate } from '../redux/certificate/actionCreator';
import { ICertificate } from '../interface/interface';
import CertificateMenu from '../components/certificate/CertificateMenu';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(18rem, 1fr));
    grid-row-gap: 3rem;
    padding: 5rem;
    background: #f4f4f4;
`;

const Certificate = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { isLoading, certificate } = useSelector((_state: any) => ({
        isLoading: _state?.certificate?.loading,
        certificate: _state?.certificate?.certificate,
    }));

    useEffect(() => {
        if (state?.id) dispatch(getAllCertificate(state?.id) as any);
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>
                    Top {state?.name} Exam Dumps Tips for a Smooth Test Day
                </title>
                <meta
                    name="description"
                    content={`Get ready for success in ${state?.name} Certification With valid PDF Dumps! Easy tips and helpful guides for a smooth journey to certification.`}
                />
            </Helmet>
            <PageTitle title={'Certificate'} />
            <Container>
                {isLoading ? (
                    <h1>Loading...</h1>
                ) : (
                    Array.isArray(certificate) &&
                    [...certificate].map((item: ICertificate, i: number) => {
                        return (
                            <CertificateMenu
                                key={item._id}
                                id={item._id}
                                name={item.name}
                                slug={item.slug}
                                vendorName={state?.name}
                                exams={item.exams}
                                primary={(i + 1) % 2 === 0}
                            />
                        );
                    })
                )}
            </Container>
        </>
    );
};

export default Certificate;
