import React from 'react';

const Arrow = () => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 370 195"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_35_10)">
                <path
                    d="M103.855 74.7078L78.7563 79.6708"
                    stroke="#6DBCC7"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    d="M78.0565 79.3927L21.9623 54.2661L65.5893 86.8551"
                    stroke="#6DBCC7"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    d="M104.762 72.1766C71.3572 61.8972 37.7591 52.2671 3.96802 43.2864"
                    stroke="#6DBCC7"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    d="M65.6358 87.6819C56.1539 87.3718 46.6691 87.3917 37.181 87.7416"
                    stroke="#6DBCC7"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    d="M1.85522 43.4758C13.7548 59.7938 26.5816 75.418 40.2726 90.2714"
                    stroke="#6DBCC7"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    d="M80.581 81.4268C79.7364 90.083 79.4149 98.7822 79.6182 107.477"
                    stroke="#6DBCC7"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    d="M66.8004 86.9729C70.7472 93.941 74.6944 100.91 78.6421 107.879"
                    stroke="#6DBCC7"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    d="M78.0923 107.794C70.544 100.693 62.6947 93.931 54.5444 87.5071"
                    stroke="#6DBCC7"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    d="M92.2168 94.2181C105.187 106.32 102.742 122.407 105.53 137.043C109.949 160.252 131.705 183.253 165.704 190.928C192.861 197.055 224.257 192.424 247.073 181.059C269.89 169.693 284.523 152.301 290.775 134.064C293.431 126.31 294.681 118.257 292.78 110.409C288.455 92.543 268.015 77.6433 243.768 68.7628C238.212 66.5193 232.327 65.1961 226.344 64.8454C220.142 64.6758 213.469 66.4357 210.608 70.0205C207.306 74.1513 210.086 79.4841 215.35 82.6421C220.615 85.8002 227.779 87.2182 234.779 88.2081C272.591 93.5687 315.271 88.281 342.799 70.6049C370.327 52.9289 378.025 22.1196 355.079 1.87659"
                    stroke="#6DBCC7"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-dasharray="9.74 9.74"
                />
            </g>
            <defs>
                <clipPath id="clip0_35_10">
                    <rect width="370" height="195" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Arrow;
