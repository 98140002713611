import actions from './action';
import { ApiService } from '../../services/ApiService';
import { API } from '../../common/api-end-point';
import { setItem } from '../../utils/localStorage';
import { localStorageVars } from '../../common/constant';

const { begin, addSuccess, getSuccess, error } = actions;
interface LoginPayload {
    email: string;
    password: string;
}

interface RegisterPayload {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

export const login = (payload: LoginPayload, callback: Function) => {
    return async (dispatch: any) => {
        dispatch(begin());

        try {
            const response = await ApiService.post(API.auth.login, payload, {
                signal: 15000,
                useAuthorization: false,
                headers: {},
            });
            const { data } = response;
            setItem(localStorageVars.token, data.token);
            dispatch(getSuccess(data.data));
            callback({ message: 'Welcome back', type: 'ok' });
        } catch (err: any) {
            console.error(err?.msg);
            callback({ message: err?.msg, type: 'err' });
            // dispatch(error(err?.message));
        }
    };
};

export const register = (payload: RegisterPayload, callback: Function) => {
    return async (dispatch: any) => {
        dispatch(begin());

        try {
            const response = await ApiService.post(API.auth.register, payload, {
                signal: 15000,
                useAuthorization: false,
                headers: {},
            });
            const { data } = response;
            setItem(localStorageVars.token, data.token);
            dispatch(getSuccess(data.data));
            callback({ message: 'Welcome back', type: 'ok' });
        } catch (err: any) {
            console.error(err);
            callback({ message: err?.msg, type: 'err' });
        }
    };
};
