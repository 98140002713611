import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import YellowText from '../components/home/BlueText';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { MdGroups2, MdOutlineQuiz, MdOutlineTimer } from 'react-icons/md';
import { BiCheckboxChecked } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { getExamById } from '../redux/exam/actionsCreator';
import { IExam } from '../interface/interface';
import { appName, color, localStorageVars } from '../common/constant';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

const Container = styled.div``;
const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 30rem;
    padding: 4em 0;
    align-items: center;
    gap: 2em;
    position: relative;
`;
const BackgroundImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
    z-index: -1;
`;
const Title = styled.h1`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 2em;
    width: 35%;
    text-align: center;
`;
const Route = styled.h3`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 1.2em;
    text-align: center;
`;
const Content = styled.div`
    padding: 5em;
    display: flex;
    gap: 1rem;
`;
const InfoContainer = styled.div`
    width: 70%;
    box-shadow: 5px 8px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
`;
const Head = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`;
interface ColumnProps {
    isOpen: boolean;
}
const Column = styled.div<ColumnProps>`
    background: ${(props) => (props.isOpen ? color : 'rgba(0, 0, 0, 0.07)')};
    padding: 1em 2em;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 0.9em;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.07);
    cursor: pointer;
    color: ${(props) => (props.isOpen ? '#fff' : '#000')};
`;
const Info = styled.div`
    padding: 2em 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
`;
const InfoHeading = styled.h3`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 1.1em;
    text-transform: capitalize;
`;
const InfoText = styled.p`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.9em;
`;
const Other = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
`;
const Card = styled.div<{ $space?: string }>`
    width: 100%;
    height: fit-content;
    padding: 1em 0;
    background-color: rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${(props) => props.$space}em;
    box-shadow: 5px 8px 4px rgba(0, 0, 0, 0.25);
`;
// const Image = styled.img`
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
// `;
const Schedule = styled.h3`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.9em;
    display: flex;
    gap: 1em;
    align-items: center;
`;
const Price = styled.div`
    width: 80%;
    padding: 1em 0;
    color: white;
    text-align: center;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.9em;
    background: ${color};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
const Button2 = styled.button`
    width: 80%;
    padding: 1em 0;
    color: ${color};
    background: none;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.9em;
    cursor: pointer;
    border: 1px solid #000000;
    transition: 0.1s ease-out;

    &:hover {
        background: lightgray;
    }
`;

const ExamDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { isAuth } = useAuth();
    const { name, id, slug, certificateName, vendorName } = state;
    const { cartItems } = localStorageVars;

    const { isLoading, exam } = useSelector((_state: any) => ({
        isLoading: _state?.exam?.loading,
        exam: _state?.exam?.payload,
    }));

    const [informationModal, setInformationModal] = useState<number>(1);

    const handleButtonClick = () => {
        if (!isAuth) return navigate('/login');
        const existingArrayString = localStorage.getItem(cartItems);
        const existingArray: IExam[] = existingArrayString
            ? JSON.parse(existingArrayString)
            : [];

        const newItem: IExam = exam!;

        const itemExists = existingArray.some(
            (item: IExam) => item?._id === newItem?._id,
        );

        if (!itemExists) {
            const updatedArray = [...existingArray, newItem];

            localStorage.setItem(cartItems, JSON.stringify(updatedArray));
            toast(`${exam?.name} add to cart successfully!`);
        }
    };

    const onInformationModalChange = (value: number) =>
        setInformationModal(value);

    useEffect(() => {
        if (id) {
            dispatch(getExamById(id) as any);
        }
    }, [id, dispatch]);

    return (
        <>
            <Helmet>
                <title>
                    {`${vendorName ?? ''} ${exam?.sku} Exam Dumps: Your Path to
                    Certification`}
                </title>
                <meta
                    name="description"
                    content={`
                            Best ${vendorName} Certified Solution Architect Associate 
                                ${
                                    exam?.sku
                                } Exam Dumps ${new Date().getFullYear()} Updated with
                                study material, Start your ${
                                    exam?.sku
                                } PDF Dumps journey to
                                certification confidently.
                            `}
                />
            </Helmet>
            <Container>
                <TitleContainer>
                    <BackgroundImg src="/img/ai.png" alt="background" />
                    <Title>{name}</Title>
                    <Route>
                        Home // Certificate // <YellowText>{slug}</YellowText>
                    </Route>
                </TitleContainer>
                <Content>
                    <InfoContainer>
                        <Head>
                            <Column
                                onClick={() => onInformationModalChange(1)}
                                isOpen={informationModal === 1}
                            >
                                Overview
                            </Column>
                            <Column
                                onClick={() => onInformationModalChange(2)}
                                isOpen={informationModal === 2}
                            >
                                Demo Questions
                            </Column>
                            {/* <Column
                                onClick={() => onInformationModalChange(3)}
                                isOpen={informationModal === 3}
                            >
                                FAQs
                            </Column>
                            <Column
                                onClick={() => onInformationModalChange(4)}
                                isOpen={informationModal === 4}
                            >
                                Reviews
                            </Column> */}
                        </Head>
                        {informationModal === 1 && (
                            <>
                                <Info>
                                    <InfoHeading>
                                        {exam?.sku}: {name} Exam Guide
                                    </InfoHeading>
                                    <InfoHeading>
                                        Welcome to {appName} - Your Gateway to
                                        Exam Excellence!
                                    </InfoHeading>
                                    <InfoText>
                                        Congratulations on taking the first step
                                        towards mastering Microsoft Azure! The{' '}
                                        {exam?.sku} exam is a pivotal
                                        certification for aspiring Azure
                                        administrators, and at
                                        {appName}, we're here to guide you
                                        through every step of your preparation.
                                    </InfoText>
                                    <InfoHeading>
                                        Why choose {exam?.sku}?
                                    </InfoHeading>
                                    <InfoText>
                                        Dive deep into {certificateName}{' '}
                                        services and gain a comprehensive
                                        understanding of cloud computing
                                        principles. Learn to deploy and manage
                                        virtual machines, secure identities, and
                                        optimize Azure resources.
                                    </InfoText>
                                    <InfoHeading>
                                        Real-World Scenarios
                                    </InfoHeading>
                                    <InfoText>
                                        Encounter practical scenarios reflecting
                                        real-world challenges in {exam?.sku}.
                                        Develop hands-on skills to confidently
                                        manage and troubleshoot{' '}
                                        {certificateName}
                                        environments.
                                    </InfoText>
                                    <InfoHeading>
                                        Career Advancement
                                    </InfoHeading>
                                    <InfoText>
                                        The {exam?.sku} certification enhances
                                        your profile, making you a sought-after
                                        professional in the cloud industry.
                                        Showcase your expertise in managing exam
                                        subscriptions, storage, and virtual
                                        networks.
                                    </InfoText>

                                    <InfoHeading>
                                        Expert Tips and Tricks
                                    </InfoHeading>
                                    <InfoText>
                                        Access exclusive tips and strategies to
                                        tackle the exam confidently. Benefit
                                        from insights shared by experienced{' '}
                                        {name}
                                        who have successfully passed the{' '}
                                        {exam?.sku}
                                        exam.
                                    </InfoText>
                                </Info>
                                <Info>
                                    <InfoHeading>Why {appName}?</InfoHeading>
                                    <InfoHeading>Focused Guidance</InfoHeading>
                                    <InfoText>
                                        Our resources are tailored specifically
                                        for {exam?.sku} exam preparation,
                                        ensuring you cover all key topics
                                        comprehensively.
                                    </InfoText>
                                    <InfoHeading>Exam Guarantee</InfoHeading>
                                    <InfoText>
                                        We are committed to your success. If
                                        you're not satisfied with your learning
                                        experience, we offer a full refund
                                        within 30 days of your purchase.
                                    </InfoText>
                                    <InfoHeading>
                                        Start Your Exam Journey Today
                                    </InfoHeading>
                                    <InfoText>
                                        Enroll in our {exam?.sku} exam guide at
                                        {appName} and embark on a journey to
                                        becoming a {name}. Secure your career in
                                        cloud computing, demonstrate your
                                        {certificateName} proficiency, and
                                        elevate your standing in the IT
                                        industry. Begin Your {appName}
                                        Adventure Now!
                                    </InfoText>
                                    <InfoHeading>
                                        Money Back Guarantee by {appName}
                                    </InfoHeading>
                                    <InfoText>
                                        The money-back guarantee offered by
                                        {appName} is a great way to make sure
                                        that you are happy with your purchase.
                                        Within 30 days of purchase, you can
                                        return your study guide for a full
                                        refund if you're not satisfied with it.
                                        Study materials from {appName} are
                                        renowned for their excellent quality and
                                        thorough coverage of exam topics.
                                        Additionally, they are written by
                                        professionals in the field who have
                                        years of IT experience. Because of this,
                                        they are a fantastic resource for anyone
                                        getting ready for an all IT
                                        certification exam.
                                    </InfoText>

                                    <InfoHeading>
                                        Get Free 90 Days Update with {appName}
                                    </InfoHeading>
                                    <InfoText>
                                        For 90 days following the purchase of a
                                        study guide, {appName} provides free
                                        updates. This implies that you can
                                        download the study guide's most recent
                                        revisions, which include any exam
                                        material updates or modifications to the
                                        test's goals. This is a fantastic
                                        approach to guarantee that you are
                                        always current on exam knowledge. You
                                        must register your study guide and
                                        create an account on our {appName} in
                                        order to receive free updates for 90
                                        days. Once your study guide has been
                                        registered, you can access the website
                                        to get the most recent revisions.
                                    </InfoText>
                                </Info>
                            </>
                        )}
                        {informationModal === 2 &&
                            (isLoading ? (
                                <Info>Loading...</Info>
                            ) : [...exam.questions].length === 0 ? (
                                <Info>No Record Found</Info>
                            ) : (
                                [...exam.questions].map((item, index) => (
                                    <Info key={item._id}>
                                        <InfoHeading>
                                            {index + 1}
                                            {':'} {item.title}
                                        </InfoHeading>
                                        <InfoText>{item.description}</InfoText>
                                        <hr />
                                    </Info>
                                ))
                            ))}
                        {informationModal === 3 && <Info>No Record Found</Info>}
                        {informationModal === 4 && <Info>No Record Found</Info>}
                    </InfoContainer>
                    <Other>
                        {/* <Card>
                            <Image src="/img/ai_kids.png" alt="kids" />
                        </Card> */}
                        <Card $space="1.5">
                            <Price>${exam?.amount}.00</Price>
                            <Button2 onClick={handleButtonClick}>
                                Add to cart
                            </Button2>
                        </Card>
                        <Card $space="1.5">
                            <Schedule>
                                <HiOutlineDocumentText color={color} /> Exam
                                Code: {exam?.sku}
                            </Schedule>
                            <Schedule>
                                <MdOutlineQuiz color={color} /> Exam Name:{' '}
                                {exam?.name}
                            </Schedule>
                            <Schedule>
                                <BiCheckboxChecked color={color} /> Question:{' '}
                                {exam?.number_of_question}
                            </Schedule>
                        </Card>
                    </Other>
                </Content>
            </Container>
        </>
    );
};

export default ExamDetail;
