import React from 'react';
import { styled } from 'styled-components';
import { BlueTextProps } from '../../types/types';

const Text = styled.span`
    color: #1e3783;
    font-family: inherit;
`;
const YellowText = ({ children }: BlueTextProps) => {
    return <Text>{children}</Text>;
};

export default YellowText;
