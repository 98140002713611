import { configureStore } from '@reduxjs/toolkit';
import certificateReducer from '../redux/certificate/reducer';
import examReducer from '../redux/exam/reducer';
import vendorReducer from '../redux/vendor/reducer';

const store = configureStore({
    reducer: {
        certificate: certificateReducer,
        exam: examReducer,
        vendor: vendorReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
