import React from 'react';
import { styled } from 'styled-components';
import Arrow from '../svg/Arrow';
import { useNavigate } from 'react-router-dom';
import { appName, color } from '../../common/constant';

const Container = styled.div`
    background: #818635;
    width: 90%;
    margin-top: 5rem;
    padding: 1rem;
    box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    width: 50%;
`;
const Header = styled.h1`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1.8em;
    color: white;

    @media (max-width: 470px) {
        font-size: 14px;
        padding: 0px;
        margin: 0px;
    }
`;
const Info = styled.h3`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 0.9em;
    color: white;

    @media (max-width: 470px) {
        font-size: 10px;
        padding: 0px;
        margin: 0px;
    }
`;
const Explore = styled.button`
    // margin-top: 4rem;
    // width: fit-content;
    padding: 1rem 2rem;
    font-family: 'Poppins';
    font-weight: 700;
    // font-size: 1em;
    color: ${color};
    border: none;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    cursor: pointer;
`;
const Graphics = styled.div`
    width: 40%;
    height: 90%;
    position: relative;
    display: flex;
`;
const Circle = styled.div`
    background: #6dbcc7;
    width: 65%;
    height: 80%;
    border-radius: 50%;
    position: absolute;
    top: 10%;
    left: 40%;
    z-index: 1;
`;
const Kid = styled.img`
    width: 80%;
    height: 80%;
    z-index: 100;
`;
const ArrowContainer = styled.div`
    width: 80%;
    height: 80%;
    position: absolute;
    top: 30%;
    left: -5%;
    z-index: 1;
`;
const ExploreSection = () => {
    const navigate = useNavigate();
    return (
        <Container>
            <Left>
                <Header>
                    Start as Early as Possible with Our Latest Study
                    Guides/Materials
                </Header>
                <Info>
                    Buy right now and pass your desire exam in first attempt.
                    Explore IT Certification Exams
                </Info>
                <Explore
                    onClick={() => {
                        navigate('/vendor');
                    }}
                >
                    Explore {appName}
                </Explore>
            </Left>
            <Graphics>
                <Circle></Circle>
                <ArrowContainer>
                    <Arrow />
                </ArrowContainer>
                <Kid src="/img/kid.png" alt="kid" />
            </Graphics>
        </Container>
    );
};

export default ExploreSection;
