import React from 'react';
import { styled } from 'styled-components';
import { DropdownItemProps } from '../../types/types';

const Container = styled.div`
    display: block;
    padding: 8px 0;
    color: #333;
    font-size: 0.9em;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        background-color: #ddd;
    }
`;
const DropdownItem = ({ children, onClick }: DropdownItemProps) => {
    return <Container onClick={onClick}>{children}</Container>;
};

export default DropdownItem;
