import React from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { styled } from 'styled-components';
import { CourseItemsProps } from '../../types/types';

const Container = styled.div`
    width: 100%;
    height: 3em;
    background: #6dbcc7;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 1em;
`;
const Title = styled.h5`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1em;
    color: white;
    width: 50%;
`;
const Divider = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    gap: 3em;
`;
const Demo = styled.div`
    color: #358186;
    background: white;
    border-radius: 10px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.9em;
    padding: 0.3em 0.5em;
`;
const Price = styled.div`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1em;
    color: white;
`;
const Cart = styled.button`
    background: #ffffff;
    color: #358186;
    border-radius: 10px;
    border: none;
    padding: 0.3em 0.5em;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    gap: 0.5em;
`;
const Item = ({ title, demo, price }: CourseItemsProps) => {
    return (
        <Container>
            <Title>{title}</Title>
            <Divider>
                {demo ? <Demo>Demo</Demo> : ''}
                <Price>${price}</Price>
                <Cart>
                    <FaShoppingCart /> Add to cart
                </Cart>
            </Divider>
        </Container>
    );
};

export default Item;
