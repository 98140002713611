import React, { ChangeEvent, useState } from 'react';
import { styled } from 'styled-components';
import { BiLock } from 'react-icons/bi';
import { GoMail } from 'react-icons/go';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import TextInput from '../components/TextInput';
import Form from '../components/Form';
import Button from '../components/Button';
import LeftLog from '../components/LeftLog';
import { login } from '../redux/auth/actionsCreator';
import { color } from '../common/constant';

const Container = styled.div`
    position: relative;
    min-height: 100%;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 1rem 0;
    }
`;
const BigRectangle = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #6dbcc7;
    border-radius: 187px;
    transform: rotate(5deg);
`;
const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
const LowerDiv = styled.div`
    padding: 1rem;
`;
const Forgot = styled.div`
    font-size: 15px;
    margin-top: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    color: #868686;
    cursor: pointer;
`;

interface FormState {
    email: string;
    password: string;
}

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [form, setForm] = useState<FormState>({
        email: '',
        password: '',
    });

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleLogin = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.preventDefault();
        dispatch(
            login(
                form,
                ({ message, type }: { message: string; type: string }) => {
                    toast(message);
                    if (type === 'ok') {
                        navigate('/', {
                            replace: true,
                        });
                    }
                },
            ) as any,
        );
    };

    return (
        <Container>
            {/* <BigRectangle></BigRectangle> */}
            <LeftLog signedup={true} />
            <Right>
                <Form title={'Sign-In'}>
                    <LowerDiv>
                        <TextInput
                            small={false}
                            icon={<GoMail size={28} color={color} />}
                            label={'Email'}
                            placeholder={'Enter Email'}
                            type={'email'}
                            name="email"
                            value={form.email}
                            onChange={onChangeHandler}
                        />
                        <TextInput
                            small={false}
                            icon={<BiLock size={28} color={color} />}
                            label={'Password'}
                            placeholder={'Password'}
                            type={'password'}
                            name="password"
                            value={form.password}
                            onChange={onChangeHandler}
                        />
                        <Forgot>Forgot Password?</Forgot>
                        <Button text={'Sign-In'} onClick={handleLogin} />
                    </LowerDiv>
                </Form>
            </Right>
        </Container>
    );
};

export default Login;
