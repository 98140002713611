import { ICertificate } from '../../interface/interface';

import actions from './action';

const { ADD_SUCCESS, GET_SUCCESS, BEGIN, ERR } = actions;

export interface State {
    total: number;
    loading: boolean;
    certificate: ICertificate[];
}

const initialState: State = {
    total: 0,
    loading: false,
    certificate: [],
};
const certificateReducer = (state = initialState, action: any) => {
    const { type, data, err } = action;
    switch (type) {
        case BEGIN:
            return {
                ...state,
                loading: true,
            };
        case ADD_SUCCESS:
            return {
                ...state,
                certificate: [...state.certificate, { ...data }],
                loading: false,
            };
        case GET_SUCCESS:
            return {
                ...state,
                certificate: data,
                loading: false,
            };
        case ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export default certificateReducer;
