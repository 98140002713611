import React from 'react';
import { styled } from 'styled-components';
import { CommentInputProps } from '../../types/types';

const Input = styled.input`
    font-size: 0.8em;
    border: none;
    background: #ffffff;
    border-radius: 50px;
    padding: 1em;
    width: 90%;
`;
const CommentInput = ({ placeholder }: CommentInputProps) => {
    return <Input placeholder={placeholder} />;
};

export default CommentInput;
