import React, { useState } from 'react';
import styled from 'styled-components';
import DropdownItem from './DropdownItem';
import { MdArrowDropDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { localStorageVars } from '../../common/constant';

const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const DropdownContent = styled.div<{ open: boolean }>`
    display: ${({ open }) => (open ? 'block' : 'none')};
    position: absolute;
    top: 120%;
    left: -50px;
    background-color: #f9f9f9;
    min-width: 80px;
    padding: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
`;

const DropdownMenu = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { token } = localStorageVars;

    const toggleDropdown = () => {
        setOpen(!open);
    };

    const handleClick = () => {
        localStorage.removeItem(token);
        navigate('/');
    };
    return (
        <DropdownContainer>
            <MdArrowDropDown
                size={20}
                style={{ cursor: 'pointer' }}
                onClick={toggleDropdown}
            />
            <DropdownContent open={open}>
                <DropdownItem
                    onClick={() => {
                        navigate('/transactions');
                    }}
                >
                    Transactions
                </DropdownItem>
                <DropdownItem onClick={handleClick}>Logout</DropdownItem>
            </DropdownContent>
        </DropdownContainer>
    );
};

export default DropdownMenu;
