import React, { ChangeEvent, useState } from 'react';
import { styled } from 'styled-components';
import TextInput from '../components/TextInput';
import Form from '../components/Form';
import Button from '../components/Button';
import LeftLog from '../components/LeftLog';
import Divider from '../components/Divider';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { register } from '../redux/auth/actionsCreator';

const Container = styled.div`
    min-height: 100%;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 1rem 0;
    }
`;
const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
const LowerDiv = styled.div`
    padding: 1rem;
    width: 100%;
`;

interface FormState {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [form, setForm] = useState<FormState>({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        confirmPassword: '',
    });

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleRegister = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.preventDefault();
        const { password, confirmPassword } = form;
        if (password !== confirmPassword) {
            return toast('Password not matched.');
        }

        dispatch(
            register(
                form,
                ({ message, type }: { message: string; type: string }) => {
                    toast(message);
                    if (type === 'ok') {
                        navigate('/', {
                            replace: true,
                        });
                    }
                },
            ) as any,
        );
    };

    return (
        <Container>
            <LeftLog signedup={false} />
            <Right>
                <Form title={'Sign-Up'}>
                    <LowerDiv>
                        <Divider>
                            <TextInput
                                small={true}
                                icon={''}
                                label={''}
                                placeholder={'First Name'}
                                type="text"
                                name="firstName"
                                onChange={onChangeHandler}
                            />
                            <TextInput
                                small={true}
                                icon={''}
                                label={''}
                                placeholder={'Last Name'}
                                type="text"
                                name="lastName"
                                onChange={onChangeHandler}
                            />
                        </Divider>
                        <TextInput
                            small={true}
                            icon={''}
                            label={''}
                            placeholder={'Email'}
                            type="email"
                            name="email"
                            onChange={onChangeHandler}
                        />
                        <TextInput
                            small={true}
                            icon={''}
                            label={''}
                            placeholder="Password"
                            type="password"
                            name="password"
                            onChange={onChangeHandler}
                        />
                        <TextInput
                            small={true}
                            icon={''}
                            label={''}
                            placeholder={'Confirm Password'}
                            type="password"
                            name="confirmPassword"
                            onChange={onChangeHandler}
                        />
                        <Button text={'Register'} onClick={handleRegister} />
                    </LowerDiv>
                </Form>
            </Right>
        </Container>
    );
};

export default Register;
